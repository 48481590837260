import styled from "styled-components";

export const StyledWindow = styled.div`
  min-height: 90%;
  max-height: 100%;
  min-width: 100%;
  background: linear-gradient(100deg, rgba(7,40,56,1) 0%, rgba(112,158,180,1) 75%, rgba(112,158,180,1) 100%);
  justify-content: center;
  padding-top: 30px;

  @media(max-height: 500px) {
    min-height: 100vh;
    max-height: 140vh;
  }
`;

export const StyledFormComponent = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  width: 50%;
  max-width: 900px;
  min-width: 300px;
  margin: 0 auto;
  min-height: 85vh;
  @media(max-width: 500px) {
    width: 85%;
  }
  a {
    color: rgba(112,158,180,1);
  }
`;

export const StyledInputsComponent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media(max-width: 500px) {
    width: 85%;
  }
`;

export const Image = styled.img`
  height: 10rem;
  width: 25rem;
  margin-bottom: 5rem;
  @media(max-width: 500px) {
    height: 7rem;
    width: 18rem;
    position: initial;
    left: 0;
  }
`;

export const Header = styled.h1`
  font-size: 20px;
  color: rgba(112,158,180,1);

  @media(max-width: 970px) {
    font-size: 25px;
    margin: 0 0 1rem 1rem;
  }
`;

export const Title = styled.p`
  font-size: 20px;
  color: rgba(112,158,180,1);
  
  @media(max-width: 970px) {
    font-size: 15px;
    margin: 0 0 1rem 1rem;
  }
`;

export const StyledButton = styled.button`
  margin: 1rem;
  font-size: 16px;
  background: transparent;
  border: none;
  color: rgba(112,158,180,0.8);
`