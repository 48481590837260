import React, { useState } from "react";
import {
    StyledWindow,
    Image,
    StyledFormComponent,
    Title,
    StyledInputsComponent,
} from "./styles"

import Logo from "../../assets/logo.png"
import TextInput from "../../components/Input";
import TextInputCEP from "../../components/InputCEP";
import Button from "../../components/Button";
import LoadingButton from "../../components/LoadingButtons";
import Selector from "../../components/Selector";
import * as yup from "yup";
import { Formik } from "formik";
import { useNavigate, useLocation } from "react-router-dom"
import isCPF from "../AppointmentCPF/isCPF";
import ReCAPTCHA from "react-google-recaptcha";
import api from "../../services/api"
import CheckBox from "../../components/CheckBox";
import Modal from "../../components/Modal";

export interface SignUpProps {
    name: string;
    gender: string;
    birthDate: string;
    cpf: string;
    cellPhone: string;
    address: {
        zipCode: string;
        complement: string;
        neighborhood: string;
        street: string; // logradouro
    };
    email: string;
}

const reviewSchema = yup.object({
    name: yup.string().required("Nome não pode ficar em branco"),
    email: yup
        .string()
        .email("E-mail inválido")
        .required("E-mail não pode ficar em branco"),
    birthDate: yup
        .string()
        .required("Data não pode ficar em branco")
        .min(10, () => "Data está incompleta"),
    cpf: yup
        .string()
        .required("CPF não pode ficar em branco")
        .min(14, () => "CPF deve ter 11 digitos")
        .test("CPF", "CPF incorreto", (value) => isCPF(value)),
    cellPhone: yup
        .string()
        .required("Telefone não pode ficar em branco")
        .min(15, () => "Telefone está incompleto"),
    address: yup.object().shape({
      zipCode: yup
        .string()
        .required("CEP não pode ficar em branco")
        .min(9, () => "CEP deve ter 8 digitos"),
      street: yup
        .string()
        .required("Logradouro não pode ficar em branco"),
        neighborhood: yup
        .string()
        .max(30, () => "Bairro com máximo de 30 caracteres")
        .required("Bairro não pode ficar em branco"),
    }),
  });




export default function SignUp(){
    const navigate = useNavigate();
    const { state } = useLocation();
    const CPF = String(state);
    const [gender, setGender] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const genderOptions = ["Masculino", "Feminino"];
    const recaptchaRef = React.useRef<any>();
    const [isChecked, setIsChecked] = useState(false);
    const [modalState, setModalState] = useState(false);
    const [textModal, setTextModal] = useState<string>("");
    const [titleModal, setTitleModal] = useState<string>("");
    


    const submitHandler = async (values: SignUpProps) => {
        values.gender = gender;
        setIsLoading(true);
        if(values.gender === ""){
            setModalState(true);
            setTitleModal("Erro no cadastro")
            setTextModal("É necessário selecionar um sexo para continuar com o cadastro");
            setIsLoading(false);
        }else if(!isChecked){
            setModalState(true);
            setTitleModal("Erro no cadastro")
            setTextModal("É necessário aceitar os termos e políticas de privacidade para continuar com o cadastro");
            setIsLoading(false);
        }
        else {
            const token = await recaptchaRef.current.executeAsync();
            if(token){
                api.post("/post/recaptcha",{
                    response: token,
                    secret: process.env.REACT_APP_RECAPTCHA
                })
                .then(res => {
                    const bot = res.data.success;
                    if(bot){
                        const cellPhoneAux = "+55" + values.cellPhone.slice(1,3) + values.cellPhone.slice(5,10) + values.cellPhone.slice(11, 15);
                        api.post("/post/verify",{
                            To: cellPhoneAux,
                            Channel:  "sms"
                        }).then(()=>{
                            navigate("/verify", {state: {values, signUp: true, cellPhone: values.cellPhone}})
                        })
                    }else{
                        window.alert("Ocorreu um erro, tente novamente mais tarde ou ligue para 3242-4222");
                    }
                })
            }
        }
    }

    return ( 
        <StyledWindow>
            <StyledFormComponent >
                < Image src={Logo} alt="Oculare-Oftalmologia" /> 
                < Title>
                    Cadastro
                </Title>
                <Formik
                    initialValues={{
                        name: "",
                        birthDate: "",
                        email: "",
                        cpf: CPF,
                        gender: "",
                        cellPhone: "",
                        address: {
                            zipCode: "",
                            complement: "",
                            neighborhood: "",
                            street: "",
                        },
                    }}
                    validationSchema={reviewSchema}
                    onSubmit={submitHandler}
                >
                    {(register) =>(
                        
                        <StyledInputsComponent onSubmit={register.handleSubmit}>
                            
                            <TextInput
                                disabled
                                text="CPF" 
                                type="text" 
                                name="cpf"
                                onChange={register.handleChange("cpf")}
                                value={register.values.cpf}
                                propsTouched={register.touched.cpf}
                                propsError={register.errors.cpf}
                                mask="999.999.999-99"
                            />

                            <TextInput
                                text="Nome Completo" 
                                type="name" 
                                name="name"
                                id="name"
                                onChange={register.handleChange("name")}
                                value={register.values.name}
                                propsTouched={register.touched.name}
                                propsError={register.errors.name}
                            />
                            <TextInput
                                text="Email" 
                                type="email" 
                                name="email"
                                id="email"
                                onChange={register.handleChange("email")}
                                value={register.values.email}
                                propsTouched={register.touched.email}
                                propsError={register.errors.email}
                            />
                            <Selector
                                title="Sexo"
                                options={genderOptions}
                                setSelector={setGender}
                                selector={gender}
                            />
                            <TextInput
                                text="Data de nascimento" 
                                type="bithdate" 
                                name="birthDate"
                                onChange={register.handleChange("birthDate")}
                                value={register.values.birthDate}
                                propsTouched={register.touched.birthDate}
                                propsError={register.errors.birthDate}
                                mask="99/99/9999"
                            />

                            <TextInput
                                text="Celular" 
                                type="cellphone" 
                                name="cellPhone"
                                id="cellPhone"
                                onChange={register.handleChange("cellPhone")}
                                value={register.values.cellPhone}
                                propsTouched={register.touched.cellPhone}
                                propsError={register.errors.cellPhone}
                                mask="(99) 99999-9999"
                            />

                            <TextInputCEP
                                text="CEP" 
                                type="text" 
                                name="zipCode"
                                onChange={register.handleChange("address.zipCode")}
                                value={register.values.address.zipCode}
                                propsTouched={register.touched.address?.zipCode}
                                propsError={register.errors.address?.zipCode}
                                mask="99999-999"
                                zip
                            />

                            <TextInput
                                text="Bairro" 
                                type="text" 
                                name="neighborhood"
                                onChange={register.handleChange("address.neighborhood")}
                                value={register.values.address.neighborhood}
                                propsTouched={register.touched.address?.neighborhood}
                                propsError={register.errors.address?.neighborhood}
                            />

                            <TextInput
                                text="Logradouro" 
                                type="text" 
                                name="street"
                                onChange={register.handleChange("address.street")}
                                value={register.values.address.street}
                                propsTouched={register.touched.address?.street}
                                propsError={register.errors.address?.street}
                            />

                            <TextInput
                                text="Complemento" 
                                type="text" 
                                name="complement"
                                onChange={register.handleChange("address.complement")}
                                value={register.values.address.complement}
                                propsTouched={register.touched.address?.complement}
                                propsError={register.errors.address?.complement}
                            />

                            <CheckBox isChecked={isChecked} setIsChecked={setIsChecked}/>
 

                            {isLoading ? 
                                <LoadingButton />
                            :
                                <Button text="Cadastrar" type="submit" disabled={isLoading}/> 
                            }       

                        </StyledInputsComponent>
                    )
                }
            </Formik>
            <Modal 
                state={modalState} 
                setState={setModalState}  
                title={titleModal} 
                text={textModal}
                buttonText={"OK"}
            />
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE ? process.env.REACT_APP_RECAPTCHA_SITE : "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}
            />
            </StyledFormComponent>
        </StyledWindow>
    )
}