import React, { useState, useEffect, useCallback } from "react";
import {
    StyledWindow,
    StyledFormComponent,
    StyledButton,
    ButtonContainer,
    Container,
    Title,
    Image,
} from "./styles"
import BounceLoader from "react-spinners/MoonLoader";
import api from "../../services/api";
import Logo from "../../assets/logo.png"

import { useLocation } from "react-router-dom"
import WppButton from "../../components/WhatsAppButton";

interface VerifyProps {
    cod: string;
}

interface FileProps {
    dat_arquivo: string;
    dat_atendimento: string;
    des_arquivo: string;
    des_extensao_imagem: string;
    id_arquivo_paciente: number;
    nom_arquivo: string;
    nom_medico: string;
    checked: boolean
}

export default function Exam(){
    // const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const[files, setFiles] = useState<FileProps[]>();
    const location  = useLocation();
    const state = location.state as VerifyProps;
    const cod = state.cod;
    const [year, setYear] = useState<number>()


    const getExams = useCallback( async () => {
        setIsLoading(true);
        console.log(cod)
        await api.get(`get/files/${cod}`)
        .then(response => {
            const date = new Date(response.data.files[0].dat_arquivo)
            setYear(date.getFullYear())
            setFiles(response.data.files)
        })
        setIsLoading(false);
    },[])

    const getExam = async(id: number, name: string) => {
        setIsLoading(true)
        await api.get(`get/file/${id}`).then(res =>{
            const base64URL = res.data;
            const binary = atob(base64URL.replace(/\s/g, ""));
            const len = binary.length;
            const buffer = new ArrayBuffer(len);
            const view = new Uint8Array(buffer);

            for (let i = 0; i < len; i += 1) {
                view[i] = binary.charCodeAt(i);
            }

            // create the blob object with content-type "application/pdf"
            const blob = new Blob([view], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);

            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = `${name}.pdf`;
            // a.target = "_blank";
            a.click();
            setIsLoading(false)
        })
    }




    useEffect( () => {
        getExams();
    },[])

    return ( 
        <StyledWindow>
            <StyledFormComponent>
                {
                    isLoading ? 
                        <BounceLoader size={60} css={"display: block;margin: 0 auto;"}/>
                    : 
                    <Container>
                        <Image src={Logo} alt="Enterprise photo" /> 
                        <Title>Últimos exames de {year}</Title>
                        <ButtonContainer>
                            {
                                files?.filter((val) =>{
                                    if(val.des_extensao_imagem === ".pdf"){
                                        return val
                                    }
                                }).map((file) =>{
                                    return(
                                        <StyledButton 
                                            key={file.id_arquivo_paciente}
                                            onClick={() => getExam(file.id_arquivo_paciente, file.des_arquivo)}>
                                            {file.des_arquivo}
                                        </StyledButton>
                                    )
                                })
                            }
                        </ButtonContainer>
                    </Container>
                }
            </StyledFormComponent>
            <WppButton/>
        </StyledWindow>
    )
}