import { Container,  DropdownField, DropdownContent, DropdownItem, StyledDropdow, StyledArrow}  from "./styles";
import { useEffect, useRef, useState } from "react";

type SelectorProps = {
  title: string;
  setSelector: React.Dispatch<React.SetStateAction<string>>;
  selector: string;
  response?: any;
  options: string[];
  setId: React.Dispatch<React.SetStateAction<number | undefined>>;
  hidden?: string | number;
};



export default function SearchSelectorDoctors({ title, options, setSelector, hidden, response, setId, selector }: SelectorProps) {
  
  const wrapperRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [select, setSelect] = useState(selector ? selector : title)

  useEffect(() =>{
    setSelect(title)
  },[hidden])

  const searchId = (option: string) =>{
    function filterId(obj: any) {
      const Name = option.split(" ");
      Name[1] = Name[1].normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      Name[2] = Name[2].normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      Name[1] = Name[1].toUpperCase();
      Name[2] = Name[2].toUpperCase();
      const filterFirst = new RegExp(Name[1]);
      const filterLast = new RegExp(Name[2]);
      
      
      if("nom_guerra" in obj && filterFirst.test(obj.nom_guerra) && filterLast.test(obj.nom_guerra)) {
        return true;
      }
    }
    if(response){
      const doctor = response.filter(filterId);
      setId(doctor[0].id_medico);
    }
  }

  function useOutsideAlerter(ref: any, ) {
    useEffect(() => {
        function handleClickOutside(ev: any) {
            if (ref.current && !ref.current.contains(ev.target)) {
                setOpen(false);
            }
        }
  
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);


  return (
    <>
    {(hidden !== "" && hidden !== 0) ?  
    <Container>
        <StyledDropdow>
          <DropdownField 
            tabIndex={0} 
            type="button" 
            onKeyPress={() => setOpen(!open)}
            onClick={() => setOpen(!open)}
            >
              {select}
          </DropdownField> 
          
          <StyledArrow className="fas fa-caret-down" />
        </StyledDropdow>
          {open && 
            <DropdownContent ref={wrapperRef}>
              {options.map(option => {
                return (
                  <DropdownItem key={option} onClick={() => {
                    setSelect(option);
                    setSelector(option);
                    setOpen(!open);
                    searchId(option);
                  }}>
                      {option}
                  </DropdownItem>
                )
              })}
            </DropdownContent>
          }
      </Container> : null}
    </>
  );

  
}