import { Container,  DropdownInputField, DropdownContent, DropdownItem, StyledDropdow}  from "./styles";
import { useEffect, useRef, useState } from "react";

interface OptionsProps{
    id_convenio: number;
    nom_convenio: string;
}

type SelectorProps = {
  title: string;
  setSelector: React.Dispatch<React.SetStateAction<any>>;
  selector: number;
  objectOptions?: OptionsProps[];
  hidden?: number | string;
};



export default function Selector({ title, objectOptions, setSelector, hidden }: SelectorProps) {
  
  const wrapperRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [select, setSelect] = useState("")
  const blackList = [
    "PARTICULAR", 
    "CORTESIA", 
    "TABELA SOCIAL", 
    "LENTES DE CONTATO 1", 
    "TRATAMENTO DE LUZ PULSADA", 
    "BAIXA VISAO", 
    "LASER TAXA DE SALA",
    "BAIXA VISAO",
    "LASER TAXA DE SALA",
    "OFTALMOLOGIA RIOS"
  ];

  useEffect(() =>{
    setSelector(0); 
  },[])

  function useOutsideAlerter(ref: any, ) {
    useEffect(() => {
        function handleClickOutside(ev: any) {
            if (ref.current && !ref.current.contains(ev.target)) {
                setOpen(false);
            }
        }
        
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);



  return (
    <>
    {hidden !== "" ?  
    <Container>
      <StyledDropdow>
        <DropdownInputField
          placeholder = {title}
          value = {select}
          onClick={() => setOpen(!open)}
          onChange={(event) => {
            setSearchTerm(event.target.value);
            setSelect(event.target.value);
            setSelector(0);
          }}
        >
        </DropdownInputField>   
        </StyledDropdow>
          {open && 
            <DropdownContent ref={wrapperRef}>
              {objectOptions?.filter((val) =>{
                if(!blackList.includes(val.nom_convenio)){
                  if(searchTerm === ""){
                    return val
                  }else if(val.nom_convenio.toLowerCase().includes(searchTerm.toLowerCase())){
                    return val
                  }
                }
              }).map(mult => {
                return (
                  <DropdownItem key={mult.id_convenio} onClick={() => {
                    setSelect(mult.nom_convenio);
                    setSelector(mult.id_convenio);
                    setOpen(!open);
                  }}>
                    {mult.nom_convenio}
                  </DropdownItem>
                )   
              })}
            </DropdownContent>
          }
      </Container> : null}
    </>
  );

  
}