import styled from "styled-components";
import { COLORS } from "../../colors"

export const StyledWindow = styled.div`
  background: ${COLORS.gradient};
  min-height: 90%;
  max-height: 100%;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 2rem;
`;

export const Title = styled.h1`
  font-size: 25px;
  color: ${COLORS.white};
  padding: 3rem;
  font-family: "NimbusSanLBold";
  @media(max-width: 970px) {
    font-size: 20px;
    padding: 3rem;
  }
`;

export const StyledFormComponent = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.primaryColor};
  border-radius: 10px;
  width: 50%;
  max-width: 900px;
  min-width: 300px;
  margin: 0 auto;
  min-height: 90vh;
  padding-bottom: 2rem;
  @media(max-width: 600px) {
    width: 85%;
  }
`;

export const StyledButton = styled.button`
  margin: 1rem;
  font-size: 16px;
  background: transparent;
  border: none;
  font-family: "NimbusSanL";
  color: ${COLORS.white};
`