import {
    StyledWindow,
    Image,
    StyledFormComponent,
    Title,
    Icon,
    Header
} from "./styles"

import Logo from "../../assets/logo.png"
import Check from "../../assets/check.png"
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import WppButton from "../../components/WhatsAppButton";

interface ConfirmationProps {
    day: number;
    month: number;
    schedule: string;
    name: string;
    auxDoctor: string;
}



export default function Confirmation(){
    const location  = useLocation();
    const state = location.state as ConfirmationProps;
    const day = state.day;
    const schedule = state.schedule;
    const name = state.name;
    let doctorName = state.auxDoctor;
    const [month, setMonth] = useState<number | string>(state.month);
    const [firstName, setFirstName] = useState<string>();
    const [firstDoctorName, setFirstDoctorName] = useState<string>();

    function capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    useEffect(() =>{
        if(month < 10){
            setMonth("0" + month);
        }

        let auxName = name.split(" ")[0];
        auxName = auxName.toLowerCase();
        auxName = capitalizeFirstLetter(auxName);
        setFirstName(auxName)
        if(doctorName === "Ant�nio"){
            doctorName = "Antônio"
        }
        setFirstDoctorName(doctorName);
    },[day])

    return ( 
        <StyledWindow>
            <StyledFormComponent>
                <Image src={Logo} alt="Oculare Oftalmologia" /> 
                <Icon src={Check} alt="check" />
                <Header>
                    Obrigado {firstName}!
                </Header>
                <Title>Sua próxima consulta foi marcada com sucesso para: {day}/{month} às {schedule} com o(a) Dr(a) {firstDoctorName}</Title>
            </StyledFormComponent>
            <WppButton/>
        </StyledWindow>
    )
}