import { useCallback, useEffect, useState } from "react";
import { StyledDiv, Image, Text, Header, Content, List, Copy } from "./styles";

interface DoctorProps {
  doctors: {
    name: string;
    image: string;
    description: string[];
  }[]
  doctor: string
}



export default function Doctor({ doctors, doctor }: DoctorProps) {
  const [image, setImage] = useState("");
  const [description, setDescription] = useState<string[]>();
  
  const filterName = useCallback((obj) =>{
    const Name = doctor.split(" ");
    const filterFirst = new RegExp(Name[1]);
    const filterLast = new RegExp(Name[2]);
    
    if("name" in obj && filterFirst.test(obj.name) && filterLast.test(obj.name)) {
      return true;
    }
  },[doctor])
  
  useEffect(() => {
    if(doctor !== ""){
      const filteredDoctor = doctors.filter(filterName);
      if(filteredDoctor.length !== 0){
        setImage(filteredDoctor[0].image);
        setDescription(filteredDoctor[0].description);
        }
    }
  },[doctor])
  
  const list = description?.map((line) => {
    return(
      <List key={line} style={{padding: "0.2rem"}}>{line}</List>
    )
  })
    return (
      doctor === "" ? 
      <StyledDiv animate={"visible"} >
        <Copy>Selecione a seguir, o tipo de atendimento (particular ou convênio), o médico especialista e a data do seu atendimento:</Copy>
      </StyledDiv>
      :
      <StyledDiv animate={"visible"} key={doctor}>
          <Image src={image}/>
          <Text>
            <Header>{doctor}</Header>
            <Content>{list}</Content>
          </Text>
      </StyledDiv>
  );
}