import styled from "styled-components";
import { COLORS } from "../../colors"

export const CheckContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`

export const StyledLabel = styled.label`
  display: flex;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const FunctionCheck = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

export const StyledCheck = styled.i`
  display: flex;
  height: 20px;
  width: 20px;
`


export const StyledCheckActivaded = styled.i`
  display: flex;
  height: 20px;
  width: 20px;
`

export const StyledButton = styled.a`
  font-size: 16px;
  padding-left: 10%;
  background: transparent;
  font-family: "NimbusSanL";
  border: none;
  color: ${COLORS.secundaryColor};
  text-decoration: underline;
  text-decoration-color: ${COLORS.secundaryColor};
  cursor: pointer;
`