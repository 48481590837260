import styled from "styled-components";
import { COLORS } from "../../colors"

export const StyledWindow = styled.div`
  min-height: 90%;
  max-height: 100%;
  background: ${COLORS.gradient};
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 2rem;
`;

export const StyledFormComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLORS.primaryColor};
  border-radius: 10px;
  width: 50%;
  max-width: 900px;
  min-width: 300px;
  margin: 0 auto;
  min-height: 90vh;
  padding-bottom: 2rem;
  -moz-box-shadow: 0px 3px 8px rgb(100, 100, 100);
  -webkit-box-shadow: 0px 3px 8px rgb(100, 100, 100);
  box-shadow: 0px 3px 8px rgb(100, 100, 100);
  @media(max-width: 970px) {
    width: 85%;
  }
`;

export const StyledInputsComponent = styled.form`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media(max-width: 970px) {
    width: 85%;
  }
`

export const Image = styled.img`
  height: 10rem;
  width: 25rem;
  @media(max-width: 970px) {
    height: 8rem;
    width: 20rem;
    position: initial;
    left: 0;
  }
`;

export const Title = styled.p`
  font-size: 1.5rem;
  color: ${COLORS.white};
  font-family: "NimbusSanLBold";
  @media(max-width: 970px) {
    font-size: 25px;
    margin: 0 0 1rem 0;
  }
`;