import {
    StyledWindow,
    StyledFormComponent,
    Header,
    Image,
    Line,
    Text,
    ButtonContainer
} from "./styles"

import Logo from "../../assets/logo.png"
import WppButton from "../../components/WhatsAppButton";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom"



export default function LandingPage(){
    const navigate = useNavigate();


    return ( 
        <StyledWindow>
            <StyledFormComponent>
                <Image src={Logo} alt="Oculare Oftalmologia" /> 
                <Header>
                    Esse é nosso sistema de agendamento e requerimento de exames. Por aqui você tem acesso ao nosso sistema interno, e com isso você pode:
                </Header>
                <Line/>
                <Text>Marcar sua consulta diretamente na agenda do médico de sua preferência</Text>
                <Line/>
                <Text>Ter acesso aos seus exames sem precisar comparecer à clínica</Text>
                <ButtonContainer>
                    <Button text="Marcar consulta" onClick={() => navigate("/appointmentcpf")}/>
                    <Button text="Acessar exames"  onClick={() => navigate("/examcpf")}/>
                </ButtonContainer>
            </StyledFormComponent>
            <WppButton/>
        </StyledWindow>
    )
}