import React, { useState } from "react";
import {
    StyledWindow,
    Image,
    StyledFormComponent,
    Title,
    StyledInputsComponent,
    Header,
} from "../AppointmentCPF/styles"


import { useNavigate } from "react-router-dom"
import AuthService from "../../services/AuthService";
import api from "../../services/api"
import Logo from "../../assets/logo.png"
import TextInput from "../../components/Input";
import Button from "../../components/Button";
import LoadingButton from "../../components/LoadingButtons";
import isCPF from "../AppointmentCPF/isCPF";
import Modal from "../../components/Modal";
import WppButton from "../../components/WhatsAppButton"
import { COLORS } from "../../colors"






export default function ExamCPF(){
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [modalState, setModalState] = useState(false);
    const [finalCPF, setFinalCPF] = useState("");
    const [textModal, setTextModal] = useState<boolean>(false); //  Eu mudo o texto da modal se o paciente
                                                           //  esta proibido ou nao de usar a aplicacao


    function ExamLogin(
      cod: string,
      cellPhone: string
    ){
        const cellPhoneAux =  "+55" + "61" + cellPhone.split("-").join("");
        api.post("/post/verify",{
            To: cellPhoneAux,
            Channel:  "sms"
        }).then(()=>{
            setTimeout(() => {
                setIsLoading(false);
                navigate("/verify",{state: {signUp: false, cod, cellPhone: "(61) " + cellPhone}})
            }, 500);
        })
        }


    async function LogUserIn(
        e: React.SyntheticEvent,
        cpf: string,
      ) {
        e.preventDefault();
        let cod = "";
        
        let cellPhone = "";
        if(isCPF(cpf)){
            setIsLoading(true);
            setFinalCPF(cpf);
            await AuthService.login({ cpf }).then(async (response) => {
                const prohibited = new RegExp("MARCACAO ONLINE PROIBIDA");
                if(prohibited.test(response.data.result[0].mem_obs)) {
                    setTextModal(true);
                    setModalState(true);
                }else{
                    cod = response.data.result[0].cod_paciente;
                    cellPhone = response.data.result[0].num_telefone1;
                    ExamLogin(cod, cellPhone);
                }
            }).catch(function (err) {
                if(err.request.status === 404){
                    setModalState(true);
                    setTextModal(false);
                    setIsLoading(false); 
                }else{
                    window.alert("Ocorreu um erro, tente novamente mais tarde ou ligue para (61) 3242-4222");
                }
            })                
        }
      }

      
      function handleChange(cpf: string) {
        const cpfDocument = document.getElementById("cpf");
        setError("");
        if(cpfDocument){
            cpfDocument.style.borderColor = COLORS.secundaryColor;   
        }
        if(cpf?.length === 14){
            if(!isCPF(cpf)){
                if(cpfDocument) cpfDocument.style.borderColor = "tomato";
                setError("CPF inválido");
            }
        }
      }


    return ( 
        <StyledWindow>
            <StyledFormComponent onSubmit={(e: React.SyntheticEvent) => {
              e.preventDefault();
              const target = e.target as typeof e.target & {
                cpf: { value: string };
              };
              const cpf = target.cpf.value;
              LogUserIn(e, cpf);
            }}>
                <Image src={Logo} alt="Enterprise photo" /> 
                <Header>
                    Bem-vindo(a)!
                </Header>
                <Title>
                    Para iniciar acessar os seus exames, digite seu CPF
                </Title>
                <StyledInputsComponent>
                    <TextInput id="cpf" mask="999.999.999-99" text="Digite seu CPF" type="tel" name="cpf" onChange={e => handleChange(e.target?.value)} error={error}/>  
                    {isLoading ? 
                        <LoadingButton />
                        :
                        <>
                            <Button text="Acessar exames" disabled={isLoading}/>
                        </>
                    }
                </StyledInputsComponent>
                <Modal 
                    state={modalState} 
                    setState={setModalState} 
                    navigation={textModal ? undefined :  {to: "/signup", options:{state: finalCPF}}} 
                    title={textModal ? "Erro" : "CPF não cadastrado"} 
                    text={
                        textModal ? 
                            "Encontramos um problema com o seu login, por favor entre em contato com 3242-4222 para agendar a sua consulta" 
                        : 
                            "Não encontramos seu CPF em nosso banco de dados."}
                    buttonText={textModal ? "OK" : "CADASTRAR AGORA"}
                />    
            </StyledFormComponent>
            <WppButton/>
        </StyledWindow>
    )
}