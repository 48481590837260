import { useState } from "react";
import { StyledModal, Header, Title, Item, Container, NotFound, TopContainer, Body,XButton, XImage } from "./styles"
import Selector from "../Selector"
import X from "../../assets/x.png"

const customStyles = {
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
  },
  content: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // right: "auto",
    // bottom: "auto",
    // marginRight: "-50%",
  },
};
interface OptionsProps{
  chave: number;
  dat_agenda: string;
  des_hora: string;
}

interface ModalProps {
  state: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  doctorName: string;
  options: OptionsProps[] | undefined;
  setKey:  React.Dispatch<React.SetStateAction<number>>;
  setSelectedTime: React.Dispatch<React.SetStateAction<string>>;
  setSelectedDay: React.Dispatch<React.SetStateAction<string | undefined>>;
  filteredMonth: string[];
}


export default function ModalCalendar({ state, setState, doctorName, options, setKey, setSelectedDay, setSelectedTime, filteredMonth}: ModalProps ) {
  const handleClose = () => setState(false);
  const [month, setMonths] = useState("")
  const [period, setPeriod] = useState("")
  const monthOptions = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro" ];
  const periodOptions = ["Manhã", "Tarde", "Sem preferência"];
  const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"]
  let notEmpty = false;
  let empty = false;

  
  const findWeekDay = (date: string) =>{
    const auxMonth = date.split("/")[0];
    const auxDay = date.split("/")[1];
    const auxYear = date.split("/")[2];
    const finalDate = auxDay + "/" + auxMonth + "/" + auxYear
    const weekDay = weekDays[new Date(finalDate).getDay()];
    return weekDay
  }


  return (
    <div>
      <StyledModal
        isOpen={state}
        onRequestClose={handleClose}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      <Body>
        <TopContainer>
          <Header>
            <Title>
              {doctorName}
            </Title>
            <XButton type="button" onClick={handleClose}>
              <XImage src={X}/>
            </XButton>
          </Header>
          {
            options?.length !== 0 ?
            <>
              <Selector title="Selecione o mês" options={filteredMonth} setSelector={setMonths} selector={month} background={2} textColor={2} keyRestart={doctorName}></Selector>
              <Selector title="Selecione o período" options={periodOptions} setSelector={setPeriod} selector={period} background={2} textColor={2} keyRestart={doctorName}></Selector>
            </> :
            null
          }
        </TopContainer>
        <Container>
          {options?.filter((val) =>{
            const index = 1 + monthOptions.findIndex((element) =>{
              if(element === month){
                return true;
              }else{
                    return false;
                  }
                })
                let chosenMonth = "";
                
                if(index >= 1){
                  if(index < 10){
                    chosenMonth = "/0" + index
                  }else{
                    chosenMonth = "/" + index
                  }
                }
                
                
                
                if(chosenMonth === ""){
                  if(period === "Manhã"){
                    if(val.des_hora < "12:00"){
                      return val;
                    }
                  }else if(period === "Tarde"){
                    if(val.des_hora >= "12:00"){
                      return val;
                    }
                  }else if(period === "" || period === "Sem preferência"){
                    return val;
                  }
                  notEmpty = false;
                  empty = false;
                }else if(val.dat_agenda.includes(chosenMonth)){
                  notEmpty = true;
                  empty = false
                  if(period === "Manhã"){
                    if(val.des_hora < "12:00"){
                      return val;
                    }
                  }else if(period === "Tarde"){
                    if(val.des_hora >= "12:00"){
                      return val;
                    }
                  }else if(period === "" || period === "Sem preferência"){
                    return val;
                  }
                }else{
                  if(!notEmpty){
                    empty = true;
                  }
                }
              }).map(mult => {
            const weekDay = findWeekDay(mult.dat_agenda)
            return (
              <Item key={mult.chave} onClick={() => {
                setSelectedTime(mult.des_hora);
                setSelectedDay(mult.dat_agenda);
                setKey(mult.chave)
                handleClose();
              }}>
                {mult.des_hora} | {mult.dat_agenda} | {weekDay}
              </Item>
            )   
          })}
          {options?.length === 0 ? <NotFound>Sem consultas disponíveis</NotFound> : null}
        </Container>
      </Body>
      </StyledModal>
    </div>
  );
}
