import styled from "styled-components";
import { COLORS } from "../../colors"

export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
`;

export const StyledDropdow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-left: 10px;
    padding-right: 5px;
    height: 3.5rem;;
    font-family: 'Roboto', sans-serif;
    border-radius: 5px;
    background: ${COLORS.secundaryColor};
`

export const DropdownField = styled.button`
    display: flex;
    font-family: "NimbusSanL";
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    font-size: 1.1rem;
    height: 35px;
    color: ${COLORS.white};
    font-family: "NimbusSanL";
    border-radius: 5px;
    background: transparent;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    cursor: pointer;
    &:focus {
    outline: none;
    }
`

export const Item = styled.span`
    font-family: "NimbusSanL";
    padding: 4px;
`

