import React, { useState } from "react";
import {
    StyledWindow,
    Image,
    StyledFormComponent,
    Title,
    StyledInputsComponent,
    Header,
} from "./styles"


import { useNavigate } from "react-router-dom"
import AuthService from "../../services/AuthService";
import api from "../../services/api"
import Logo from "../../assets/logo.png"
import TextInput from "../../components/Input";
import Button from "../../components/Button";
import LoadingButton from "../../components/LoadingButtons";
import isCPF from "./isCPF";
import Modal from "../../components/Modal";
import { doctorOptions } from "../../data/doctors";
import WppButton from "../../components/WhatsAppButton"
import { COLORS } from "../../colors";
import AppointmentLogin from "../../services/AppointmentLogin"

interface AppointmetProps {
    day?: string;
    month?: string;
    schedule?: string;
    name?: string;
    auxDoctor?: string;
    future:boolean;
    cpf?: string;
    cod?: string;
    newDoctorOptions?: string[];
}





export default function AppointmentCPF(){
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [modalState, setModalState] = useState(false);
    const [finalCPF, setFinalCPF] = useState("");
    const [textModal, setTextModal] = useState<boolean>(false); //  Eu mudo o texto da modal se o paciente
                                                           //  esta proibido ou nao de usar a aplicacao


    async function LogUserIn(
        e: React.SyntheticEvent,
        cpf: string,
      ) {
        e.preventDefault();
        let cod = "";
        let name = "";
        if(isCPF(cpf)){
            setIsLoading(true);
            setFinalCPF(cpf);
            await AuthService.login({ cpf }).then(async (response) => {
                const prohibited = new RegExp("MARCACAO ONLINE PROIBIDA");
                if(prohibited.test(response.data.result[0].mem_obs)) {
                    setTextModal(true);
                    setModalState(true);
                }else{
                    cod = response.data.result[0].cod_paciente;
                    name = response.data.result[0].nom_paciente;
                    let LGPD : boolean = false;
                    await api.get(`/get/LGPD/${cod}`).then((resp) =>{   
                        if(resp.data.result.length) LGPD = true
                    })
                    const confirmationObject  = await AppointmentLogin(cod, cpf, name) as unknown as AppointmetProps;
                    if(confirmationObject.future){
                        setTimeout(() => {
                            setIsLoading(false);
                            navigate("/confirmation",  {state: {
                                day: confirmationObject.day,
                                month: confirmationObject.month,
                                schedule: confirmationObject.schedule,
                                name: confirmationObject.name,
                                auxDoctor: confirmationObject.auxDoctor
                            }});
                        }, 700);
                    }else{
                        setTimeout(() => {
                            setIsLoading(false);
                            navigate("/appointment", {state: {
                                cpf: confirmationObject.cpf,
                                cod: confirmationObject.cod, 
                                newDoctorOptions: confirmationObject.newDoctorOptions, 
                                LGPD
                            }})
                        }, 700);
                    }
                }
            }).catch(function (err) {
                if(err.request.status === 404){
                    setModalState(true);
                    setTextModal(false);
                    setIsLoading(false); 
                }else{
                    window.alert("Ocorreu um erro, tente novamente mais tarde ou ligue para (61) 3242-4222");
                }
            })                
        }
      }

      
      function handleChange(cpf: string) {
        const cpfDocument = document.getElementById("cpf");
        setError("");
        if(cpfDocument){
            cpfDocument.style.borderColor = COLORS.secundaryColor;   
        }
        if(cpf?.length === 14){
            if(!isCPF(cpf)){
                if(cpfDocument) cpfDocument.style.borderColor = "tomato";
                setError("CPF inválido");
            }
        }
      }


    return ( 
        <StyledWindow>
            <StyledFormComponent onSubmit={(e: React.SyntheticEvent) => {
              e.preventDefault();
              const target = e.target as typeof e.target & {
                cpf: { value: string };
              };
              const cpf = target.cpf.value;
              LogUserIn(e, cpf);
            }}>
                <Image src={Logo} alt="Enterprise photo" /> 
                <Header>
                    Bem-vindo(a)!
                </Header>
                <Title>
                    Para iniciar o processo de agendamento, digite seu CPF
                </Title>
                <StyledInputsComponent>
                    <TextInput id="cpf" mask="999.999.999-99" text="Digite seu CPF" type="tel" name="cpf" onChange={e => handleChange(e.target?.value)} error={error}/>  
                    {isLoading ? 
                        <LoadingButton />
                        :
                        <>
                            <Button text="Marcar consulta" disabled={isLoading}/>
                        </>
                    }
                </StyledInputsComponent>
                <Modal 
                    state={modalState} 
                    setState={setModalState} 
                    navigation={textModal ? undefined :  {to: "/signup", options:{state: finalCPF}}} 
                    title={textModal ? "Erro" : "CPF não cadastrado"} 
                    text={
                        textModal ? 
                            "Encontramos um problema com o seu login, por favor entre em contato com 3242-4222 para agendar a sua consulta" 
                        : 
                            "Não encontramos seu CPF em nosso banco de dados."}
                    buttonText={textModal ? "OK" : "CADASTRAR AGORA"}
                />    
            </StyledFormComponent>
            <WppButton/>
        </StyledWindow>
    )
}