import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppointmentCPF from "../pages/AppointmentCPF"
import ExamCPF from "../pages/ExamCPF"
import SignUp from "../pages/SignUp"
import Appointment from "../pages/Appointment"
import Confirmation from "../pages/Confirmation"
import Verify from "../pages/PhoneVerify"
import Exam from "../pages/Exam"
import LGPD from "../pages/LGPD"
import CPFSpecificDoctor from "../pages/CPFSpecificDoctor"
import LandingPage from "../pages/LandingPage/indes";

const AuthRoutes: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/appointmentcpf" element={<AppointmentCPF />} />
      <Route path="/examcpf" element={<ExamCPF />} />
      <Route path="/specific/name=:nameDoctor" element={<CPFSpecificDoctor />} />
      <Route path="/signup" element={<SignUp />}/>
      <Route path="/verify" element={<Verify />}/>
      <Route path="/appointment" element={<Appointment />} />
      <Route path="/confirmation" element={<Confirmation/>}/>
      <Route path="/exam" element={<Exam/>}/>
      <Route path="/lgpd" element={<LGPD/>}/>
      <Route path="/" element={<LandingPage/>}/>
    </Routes>
  </BrowserRouter>
)

export default AuthRoutes