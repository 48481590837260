import { createGlobalStyle } from "styled-components";

import NimbusSanL from "./NimbusSanL-Reg.otf";
import NimbusSanLBold from "./NimbusSanL-Bol.otf";
// import NimbusSanLBold from "./NimbusSanL-Bol.woff";
// import NimbusSanLBold2 from "./NimbusSanL-Bol.woff2";

export default createGlobalStyle`
    @font-face {
        font-family: "NimbusSanL";
        src: local("NimbusSanL"), local("NimbusSanL"),
        url(${NimbusSanL}) format("woff");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: "NimbusSanLBold";
        src: local("NimbusSanLBold"), local("NimbusSanLBold"),
        url(${NimbusSanLBold}) format("woff");
        font-weight: 300;
        font-style: normal;
    }
`;