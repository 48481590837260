import styled from "styled-components";



export const StyledWpp = styled.a`
    display: block;
    position: fixed;
    width: 3.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
`

export const StyledImage = styled.img`
    width: 3.5rem;
`