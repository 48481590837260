import * as React from "react";
import { 
  StyledModal,
  Body,
  Title,
  Text,
  StyledButton,
} from "./styles"
import { useNavigate } from "react-router-dom"

const customStyles = {
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
  },
  content: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // right: "auto",
    // bottom: "auto",
    // marginRight: "-50%",
  },
};

interface ModalProps {
  state: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  navigation?:{ 
    to: string, 
    options?: {
      replace?: boolean;
      state?: any;
    } 
    | undefined};
  title: string;
  text: string;
  buttonText: string;
}

export default function Modal({ state, setState, navigation, title, text, buttonText }: ModalProps ) {
  const handleClose = () => setState(false);
  const navigate = useNavigate();

  return (
    <div>
      <StyledModal
        isOpen={state}
        onRequestClose={handleClose}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      <Body>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <StyledButton onClick={() =>{
          handleClose();
          if(navigation){
            navigate(navigation.to, {state: navigation.options?.state})
          }
        }}>{buttonText}</StyledButton>
      </Body>
      </StyledModal>
    </div>
  );
}
