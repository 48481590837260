import Router from "./routes/Router"
import TagManager from "react-gtm-module"
import GlobalFonts from "./fonts/NimbusSanL";

const tagManagerArgs = {
    gtmId: "GTM-NK34ZF4" // Estava GTM-P23JZG8
}

TagManager.initialize(tagManagerArgs)


function App() {
  
  
  return (
    <>
      <Router/>
      <GlobalFonts/>
    </>
  );
}

export default App;
