import styled from "styled-components";
import { COLORS } from "../../colors"

export const StyledWindow = styled.div`
  background: ${COLORS.gradient};
  min-height: 90%;
  max-height: 100%;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 2rem;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  color: ${COLORS.white};
  padding: 3rem;

  @media(max-width: 970px) {
    font-size: 1.3rem;
    padding: 1rem;
  }
`;

export const Image = styled.img`
  height: 10rem;
  width: 25rem;
  @media(max-width: 600px) {
    height: 8rem;
    width: 20rem;
    position: initial;
    left: 0;
  }
`;

export const StyledFormComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.primaryColor};
  border-radius: 10px;
  width: 50%;
  max-width: 900px;
  min-width: 300px;
  margin: 0 auto;
  min-height: 85vh;
  @media(max-width: 500px) {
    width: 85%;
  }
`;




export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

`


export const ButtonContainer = styled.div`
  overflow: auto;
  height: 36vh;
  margin-bottom: 2rem;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  
`

export const StyledButton = styled.button`
  color: ${COLORS.white};
  max-height: 100px;
  display: flex;
  cursor: pointer;
  width: 100%;
  border: none;
  background: ${COLORS.secundaryColor};
  font-size: 1.1rem;
  padding: 12px;
  text-decoration: none;
  border-bottom-width: 0.1rem;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  border: solid ${COLORS.secundaryColor};
  display: block;
  &:focus {
    outline: none;
  }
`;
