import styled from "styled-components";
import Modal from "react-modal";
import { COLORS } from "../../colors"

export const StyledModal = styled(Modal)`
  
`

export const Body = styled.div`
  background: ${COLORS.primaryColor};
  width: 38vw;
  height: 45rem;
  display:flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 1rem;
  @media(max-width: 970px) {
    width:18rem;
    height: 45rem;
  }
`

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  width:25vw;
  @media(max-width: 970px) {
    width:14rem;
  }
  align-items: center;
`

export const Container = styled.div`
  height: 62%;
  width: 28vw;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  padding: 0.5rem;
  @media(max-height: 800px) {
    height: 63%;
    padding: 0;
  }
  @media(max-width: 970px) {
    width:18rem;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Title = styled.h1`
  font-size: 25px;
  font-family: "NimbusSanL";
  @media(max-width: 970px) {
    font-size: 20px;
    margin: 0 0 1rem 1rem;

  }
`;

export const XButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
`

export const XImage = styled.img`
  height: 1.2rem;
  weight: 1.2rem;
`


export const Item = styled.button`
    color: ${COLORS.white};
    font-family: "NimbusSanL";
    max-height: 100px;
    display: flex;
    cursor: pointer;
    width: 100%;
    border: none;
    background: ${COLORS.secundaryColor};
    font-size: 1.1rem;
    padding: 12px;
    text-decoration: none;
    border-bottom-width: 0.1rem;
    border-radius: 10px;
    margin-bottom: 0.5rem;
    border: solid ${COLORS.secundaryColor};
    display: block;
    
`
export const NotFound = styled.h3`
    display: flex;
    justify-content: center;
`