import styled from "styled-components";
import { COLORS } from "../../colors"

interface DropDownProps {
    background: number;
}

interface Field {
    textColor: number;
}


export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
`;

// Esse 101 fica alinhado com o resto dos inputs
export const StyledDropdow = styled.label<DropDownProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 101%;
    padding-left: 10px;
    padding-right: 5px;
    height: 3.7rem;
    font-family: "NimbusSanL";
    border-radius: 5px;
    background: ${COLORS.secundaryColor};
    margin-bottom: 1rem;
`

export const DropdownField = styled.button<Field>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    font-size: 1.1rem;
    height: 35px;
    color: ${COLORS.white};
    font-family: "NimbusSanL";
    border-radius: 5px;
    background: transparent;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    cursor: pointer;
    &:focus {
        outline: none;
    }
`
export const DropdownContent = styled.div`
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    width: 24vw;
    max-height: 145px;
    flex-direction: column;
    background: ${COLORS.secundaryColor};
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    @media(max-width: 970px) {
        max-height: 160px;
        width: 70%;
    }
    margin-top: 0.5rem;
`

export const DropdownItem = styled.button`
    color: ${COLORS.white};
    max-height: 50px;
    font-family: "NimbusSanL";
    display: flex;
    flex-direction: column;
    align-items: start;
    cursor: pointer;
    width: 100%;
    border: none;
    background: transparent;
    font-size: 1.1rem;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
`

export const StyledArrow = styled.i`
  font-size: 22px;
  color: ${COLORS.white};
  margin-top: 0.3rem;
  cursor: pointer;
`;

