import styled from "styled-components";
import { COLORS } from "../../colors"

export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
`;

export const StyledDropdow = styled.label`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-left: 10px;
    padding-right: 5px;
    height: 3.5rem;
    border-radius: 5px;
    background: ${COLORS.secundaryColor};
`

export const DropdownField = styled.button`
    font-family: "NimbusSanL";
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    font-size: 1.1rem;
    height: 35px;
    color: ${COLORS.white};
    border-radius: 5px;
    background: transparent;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    cursor: pointer;
    &:focus {
    outline: none;
    }
`
export const DropdownContent = styled.div`
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    width: 23%;
    max-height: 246px;
    flex-direction: column;
    margin-top: 0.5rem;
    background-color: ${COLORS.secundaryColor};
    border-radius: 5px;
    @media(max-width: 801px) {
        max-height: 220px;
        width: 70%;
    }
`

export const DropdownItem = styled.button`
    color: ${COLORS.white};
    font-family: "NimbusSanL";
    max-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: start;
    cursor: pointer;
    width: 100%;
    border: none;
    background: transparent;
    font-size: 1.1rem;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    
    // &:hover {
    //     background-color: #f1f1f1
    // }
`

export const StyledArrow = styled.i`
  font-size: 22px;
  color: ${COLORS.white};
  margin-top: 0.3rem;
  cursor: pointer;
`;