import { StyledButton, ButtonContainer} from "./styles";
import BounceLoader from "react-spinners/BounceLoader";


export default function LoadingButton() {
  return (
    <ButtonContainer>
      <StyledButton disabled={true}>
        <BounceLoader size={30} css={"display: block;margin: 0 auto;"}/>
      </StyledButton>
    </ButtonContainer>
  );
}