import { StyledWpp, StyledImage } from "./styles";
import Wpp from "../../assets/whatsapp-logo.png"


function WppButton() {
  
  
  return (
    <StyledWpp href="https://api.whatsapp.com/send?phone=556132424222">
        <StyledImage src={Wpp} />
    </StyledWpp>
  );
}

export default WppButton;
