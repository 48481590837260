import { useState } from "react";
import {Container, StyledInput, StyledButton, InputsContainer} from "./styles"

interface OTPProps {
    setOtpValue: React.Dispatch<React.SetStateAction<any[] | undefined>>
}


export default function OTPInput({setOtpValue}: OTPProps){
    const [otp, setOtp] = useState(new Array(6).fill(""));

    const handleChange = (element: EventTarget & HTMLInputElement, index: number) =>{
        if(isNaN(parseInt(element.value))) return false;

        setOtp([...otp.map((d, idx) => (idx === index) ? element.value : d)]);
        setOtpValue([...otp.map((d, idx) => (idx === index) ? element.value : d)]);
        if(element.nextSibling && element.nextSibling?.nodeName === "INPUT"){
            (element.nextSibling as HTMLElement)?.focus();
        }
    }

    const maxLengthCheck = (object: React.ChangeEvent<HTMLInputElement>) => {
        if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
          }
    }

    const clear = () =>{
        setOtp([...otp.map(() => "")]);
        setOtpValue([...otp.map(() => "")]);
    }

    return(
        <Container>
            <InputsContainer>
                {
                otp.map( (data, index) =>{
                    return ( 
                        
                        <StyledInput
                            type="number"
                            name="otp"
                            maxLength={1}
                            key={index}
                            value={data}
                            onChange={e => handleChange(e.target, index)}
                            onFocus={e => e.target.select()}
                            onInput={maxLengthCheck}
                        />
                        )
                    } )   
                }
            </InputsContainer>
        <StyledButton onClick={clear} type="button" >Limpar</StyledButton>

        </Container>
    )

}