import { useCallback, useEffect, useState } from "react";
import {
    StyledWindow,
    Image,
    StyledFormComponent,
    Title,
} from "./styles"

import Logo from "../../assets/logo.png"
import { useNavigate, useLocation } from "react-router-dom";
import WppButton from "../../components/WhatsAppButton";



import AppointmentFilter from "../../components/AppointmentFilter"
import AuthService from "../../services/AuthService";


interface AppointmentProps {
    cpf: string;
    cod: string;
    cod_procedimento: string;
    dat_agenda: string;
    des_hora: string;
    des_procedimento: string;
    nom_medico: string;
    newDoctorOptions: string[];
    doctor: string;
    LGPD: boolean;
}

export default function Appointment(){
    const location  = useLocation();
    const state = location.state as AppointmentProps;
    const cod = state.cod;
    const cpf = state.cpf;
    const doctor = state.doctor;
    const doctorOptions = state.newDoctorOptions;
    const LGPD = state.LGPD;
    const navigate = useNavigate();
    const [marked, setMarked] = useState(true);
    const [newPatient, setNewPatient] = useState(true);

    function capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const confirmation = useCallback( async() => {
        if(cod){

            await AuthService.getUser(cod).then(response =>{
                let last = 0;
                let schedule = "";
                let doctorName = "";
                const appointments = response.data.result;
                if(appointments.length) setNewPatient(false);
                appointments.map((appointment: AppointmentProps) => {
                    const aux = new Date(appointment.dat_agenda).getTime();
                    if(last < aux){
                        last = aux;
                        schedule = appointment.des_hora;
                        doctorName = appointment.nom_medico;
                    }
                })
                const Today = new Date(); // Data do dia de hoje
                const lastDate = new Date(last); // Data da ultima consulta marcada
                const d1 = Today.getTime();
                const d2 = lastDate.getTime();
                const future = d1 < d2;
                const day = lastDate.getDate();
                const month = lastDate.getMonth() + 1;
                let auxDoctor = doctorName.split(" ")[0];
                auxDoctor = auxDoctor.toLowerCase();
                auxDoctor = capitalizeFirstLetter(auxDoctor);
                if(future){
                    setTimeout(() => {
                        navigate("/confirmation",  {state: {
                            day,
                            month,
                            schedule,
                            name,
                            auxDoctor,
                        }});
                    }, 700);
                }else{
                    setMarked(false);
                }
            })
        }else{
            setMarked(false);
        }
    },[])

    useEffect(() =>{
        confirmation();
    },[])

    
    return ( 
        <StyledWindow>
                <StyledFormComponent>
                    < Image src={Logo} alt="Oculare Oftalmologia" /> 
                {marked ? null : 
                    <>
                        < Title>
                            Marque sua consulta
                        </Title>
                        <AppointmentFilter cpf={cpf} doctorOptions={doctorOptions} newPatient={newPatient} selectedDoctor={doctor} LGPD={LGPD}/>
                    </>
                }
                </StyledFormComponent>
                <WppButton/>
        </StyledWindow>
    )
}