import styled from "styled-components";
import Modal from "react-modal";
import { COLORS } from "../../colors"

export const StyledModal = styled(Modal)`
    background-color: transparent;
`

export const Body = styled.div`
    background: ${COLORS.primaryColor};
    width:18rem;
    height: 15rem;
    max-height: 26rem;
    display:flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 1rem;
`

export const Title = styled.h1`
    font-size: 1.7rem;
    font-family: "NimbusSanLBold";
    font-weight: 450;
    color:  ${COLORS.white}
`

export const Text = styled.div`
    font-family: "NimbusSanL";
    font-size: 1.1rem;
    align-items: center;
    padding-left: 1rem;
    color:  ${COLORS.white}
`

export const StyledButton = styled.button`
    color:  ${COLORS.white};
    border: 2px solid ${COLORS.secundaryColor};
    position: absolute;
    bottom: 30px;
    padding: 10px;
    font-family: "NimbusSanL";
    font-size: 15px;
    margin: 0;
    background: ${COLORS.secundaryColor};
    cursor: pointer;
    &:focus: {
        border: 2px solid ${COLORS.secundaryColor},
    };
    border-radius: 5px;
`