import styled from "styled-components";

export const StyledInputsComponent = styled.form`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media(max-width: 970px) {
    width: 85%;
  }
`
export const CheckContainer = styled.div`
  margin-top: 2rem;
`
