import styled from "styled-components";
import { COLORS } from "../../colors"

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1vh;
`;

export const StyledButton = styled.button`
  display: block;
  align-self: center;
  font-size: 1.1rem;
  border: none;
  min-width: 200px;
  font-family: "NimbusSanL";
  color: ${COLORS.white};
  min-height: 45px;
  background: ${COLORS.gray};
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  @media(max-width: 970px) {
    font-size: 1rem;
    min-width: 160px;
    min-height: 45px
  }
`;

export const StyledImage = styled.img`
    width: 2rem;
    height: 2rem;
`