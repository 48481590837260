import styled from "styled-components";
import { COLORS } from "../../colors"

export const Container = styled.div`
    margin-bottom: 2.5rem;
    display: flex;
    justifyContent: space-between;
    flex-direction: column; 
`

export const InputsContainer = styled.div`
    margin-bottom: 2.2rem;
    @media(max-width: 800px) {
        margin-bottom: 1.2rem;
    }
`

export const StyledInput = styled.input`
    width: 2rem;
    height: 5rem;
    color: ${COLORS.white};
    margin: 1rem;
    font-size: 1.5rem;
    text-align: center; 
    font-family: "NimbusSanL";
    border: none;
    border-bottom: 1px solid black;
    background: transparent;
    &:focus {
        outline: none;
    }
    
    ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    -moz-appearance: textfield;
      
    @media(max-width: 900px) {
        width: 0.7rem;
        height: 3rem;
        margin: 0.7rem;
        font-size: 1.2rem;
      }
`

export const StyledButton = styled.button`
  margin: 1rem;
  font-size: 16px;
  background: transparent;
  border: none;
  color: ${COLORS.white};
  font-family: "NimbusSanL";
`