import styled from "styled-components";
import InputMask from "react-input-mask";
import { COLORS } from "../../colors"

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center;
`;

export const StyledInput = styled.div` 
  display: block;
  width: 100%;
  padding-left: 15px;
  font-size: 1.1rem;
  height: 3.4rem;
  border: 0.1rem solid ${COLORS.secundaryColor};
  font-family: "NimbusSanL";
  border-radius: 5px;
  margin-bottom: 1rem;
  background: ${COLORS.secundaryColor};
`

export const InputField = styled.input`
  display: block;
  font-size: 1.1rem;
  width: 90%;
  height: 3.2rem;
  color: ${COLORS.white};
  font-family: "NimbusSanL";
  background: transparent;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  ::placeholder {
    color: ${COLORS.lighterWhite};
  }
  &:focus {
    outline: none;
  }
  &:-webkit-autofill,
  -webkit-autofill:hover, 
  -webkit-autofill:focus, 
  -webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px ${COLORS.secundaryColor} inset !important;
      -webkit-text-fill-color: ${COLORS.white};
      -webkit-height: 3000px !important;
      
  }
  margin-bottom: 0.5rem;
`;

export const InputFieldMask = styled(InputMask)`
  display: block;
  font-size: 1.1rem;
  width: 90%;
  height: 3.2rem;
  color: ${COLORS.white};
  font-family: "NimbusSanL";
  background: transparent;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  ::placeholder {
    color: ${COLORS.lighterWhite};
  }
  &:focus {
    outline: none;
  }
  &:-webkit-autofill,
  -webkit-autofill:hover, 
  -webkit-autofill:focus, 
  -webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px ${COLORS.secundaryColor} inset !important;
      -webkit-text-fill-color: ${COLORS.white};
      -webkit-height: 3000px !important;
      
  }
  margin-bottom: 0.5rem;
`;


export const Error = styled.span`
  color: tomato;
  font-size: 15px;
`
