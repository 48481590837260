import { StyledButton, ButtonContainer, StyledButtonDisabled } from "./styles";

type ButtonProps = {
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  id?: string;
};

export default function Button({ text, onClick, type, disabled, id }: ButtonProps) {
  return (
    <ButtonContainer>
      {
        disabled ? 
          <StyledButtonDisabled onClick={onClick} type={type} disabled={true} id={id} >{text}</StyledButtonDisabled>
        :
          <StyledButton onClick={onClick} type={type} disabled={disabled} id={id} >{text}</StyledButton>
      }
    </ButtonContainer>
  );
}