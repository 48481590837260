import React, { useEffect, useState } from "react";
import {
    StyledWindow,
    Image,
    StyledFormComponent,
    Title,
    StyledInputsComponent,
    Header,
} from "./styles"

import { useNavigate, useParams } from "react-router-dom"
import AuthService from "../../services/AuthService";
import Logo from "../../assets/logo.png"
import TextInput from "../../components/Input";
import Button from "../../components/Button";
import ShowDoctors from "../../components/ShowDoctors"
import LoadingButton from "../../components/LoadingButtons";
import isCPF from "../AppointmentCPF/isCPF";
import Modal from "../../components/Modal";
import { doctorOptions, doctorDescription } from "../../data/doctors"
import WppButton from "../../components/WhatsAppButton";
interface AppointmentProps {
    cod_procedimento: string
    dat_agenda: string
    des_hora: string
    des_procedimento: string
    nom_medico: string
}
import { COLORS } from "../../colors"


export default function CPFSpecificDoctor(){
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [modalState, setModalState] = useState(false);
    const [finalCPF, setFinalCPF] = useState("");
    const {nameDoctor} = useParams();
    const [doctor, setDoctor] = useState("");
    const [textModal, setTextModal] = useState<boolean>(false); //  Eu mudo o texto da modal se o paciente
                                                           //  esta proibido ou nao de usar a aplicacao

    useEffect(() =>{
        if(nameDoctor)
            setDoctor(nameDoctor)
    },[])


    function capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function highestOccurrence(arr: string[]){
        return arr.sort((a,b) =>
              arr.filter(v => v===a).length
            - arr.filter(v => v===b).length
        ).pop();
    }

    async function LogUserIn(
        e: React.SyntheticEvent,
        cpf: string,
      ) {
        e.preventDefault();
        let cod = "";
        let name = "";
        let doctorName = "";
        if(isCPF(cpf)){
            setIsLoading(true);
            setFinalCPF(cpf);
            await AuthService.login({ cpf }).then(async (res) => {
                const prohibited = new RegExp("MARCACAO ONLINE PROIBIDA");
                if(prohibited.test(res.data.result[0].mem_obs)) {
                    setTextModal(true);
                    setModalState(true);
                }else{
                    cod = res.data.result[0].cod_paciente;
                    name = res.data.result[0].nom_paciente;
                    await AuthService.getUser(cod).then(response =>{
                        let last = 0;
                        let schedule = "";
                        const appointments = response.data.result;
                        const patientDoctors: string[] = [];
                        
                        appointments.map((appointment: AppointmentProps) => {
                            const aux = new Date(appointment.dat_agenda).getTime();
                            patientDoctors.push(appointment.nom_medico);
                            
                            if(last < aux){
                                last = aux;
                                schedule = appointment.des_hora;
                                doctorName = appointment.nom_medico;
                            }
                        })
                        
                        const Today = new Date(); // Data do dia de hoje
                        const lastDate = new Date(last); // Data da ultima consulta marcada
                        const d1 = Today.getTime();
                        const d2 = lastDate.getTime();
                        const future = d1 < d2;
                        const day = lastDate.getDate();
                        const month = lastDate.getMonth() + 1;
                        let auxDoctor = doctorName.split(" ")[0];
                        auxDoctor = auxDoctor.toLowerCase();
                        auxDoctor = capitalizeFirstLetter(auxDoctor);
                        if(future){
                            setIsLoading(false);
                            setTimeout(() => {
                                    navigate("/confirmation",  {state: {
                                            day,
                                            month,
                                            schedule,
                                            name,
                                            auxDoctor
                                        }});
                                    }, 700);
                        }else{
                            let patientDoctor =  highestOccurrence(patientDoctors);
                            let change = false;
                            const newDoctorOptions = doctorOptions.filter((value: string) =>{
                                const Name = value.split(" ");
                                Name[1] = Name[1].normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                                Name[2] = Name[2].normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                                Name[1] = Name[1].toUpperCase();
                                Name[2] = Name[2].toUpperCase();
                                const filterFirst = new RegExp(Name[1]);
                                const filterLast = new RegExp(Name[2]);
                                if(!(filterFirst.test(patientDoctor ? patientDoctor : "") && filterLast.test(patientDoctor ? patientDoctor : ""))){
                                    return value;
                                }else{
                                    patientDoctor = value;
                                    change = true;
                                }
                            })
                            if(change)
                                newDoctorOptions.unshift(patientDoctor ? patientDoctor :  "")
                            
                            setIsLoading(false);
                            setTimeout(() => {
                                navigate("/appointment", {state: {cpf, cod, newDoctorOptions, doctor}})
                            }, 500);
                        }
                    })
                }
            }).catch(function (err) {
                if(err.request.status === 404){
                    setModalState(true);
                    setIsLoading(false); 
                }else{
                    window.alert("Ocorreu um erro, tente novamente mais tarde ou ligue para 3242-4222");
                }
            })


            setIsLoading(false);
        }
      }

      function handleChange(cpf: string) {
        const cpfDocument = document.getElementById("cpf");
        setError("");
        if(cpfDocument){
            cpfDocument.style.borderColor = COLORS.secundaryColor;   
        }
        if(cpf?.length === 14){
            if(!isCPF(cpf)){
                if(cpfDocument) cpfDocument.style.borderColor = "tomato";
                setError("CPF inválido");
            }
        }
      }

    return ( 
        <StyledWindow>
            <StyledFormComponent onSubmit={(e: React.SyntheticEvent) => {
              e.preventDefault();
              const target = e.target as typeof e.target & {
                cpf: { value: string };
              };
              const cpf = target.cpf.value;

              LogUserIn(e, cpf);
            }}>
                <Image src={Logo} alt="Enterprise photo" /> 
                <Header>
                    Bem-vindo(a)!
                </Header>
                <Title>
                    Seu médico selecionado foi:
                </Title>
                <ShowDoctors doctors={doctorDescription} doctor={doctor}/>
                <Title>
                    Para continuar seu agendamento com o(a)<br/>
                    {doctor}, digite o seu CPF.
                </Title>
                <StyledInputsComponent>
                    <TextInput id="cpf" mask="999.999.999-99" text="Digite seu CPF" type="zip" name="cpf" onChange={e => handleChange(e.target?.value)} error={error}/>  
                    {isLoading ? 
                        <LoadingButton />
                        :
                        <Button text="Conferir" disabled={isLoading}/>
                    }
                </StyledInputsComponent>
                <Modal 
                    state={modalState} 
                    setState={setModalState} 
                    navigation={textModal ? undefined :  {to: "/signup", options:{state: finalCPF}}} 
                    title={textModal ? "Erro" : "CPF não cadastrado"} 
                    text={
                        textModal ? 
                            "Encontramos um problema com o seu login, por favor entre em contato com 3242-4222 para agendar a sua consulta" 
                        : 
                            "Não encontramos seu CPF em nosso banco de dados."}
                    buttonText={textModal ? "OK" : "CADASTRAR AGORA"}
                /> 
            </StyledFormComponent>
            <WppButton/>
        </StyledWindow>
    )
}