import api from "./api";
import isCPF from "../pages/AppointmentCPF/isCPF";
import AuthService from "./AuthService";
import { doctorOptions } from "../data/doctors";

interface AppointmentProps {
  cod_procedimento: string
  dat_agenda: string
  des_hora: string
  des_procedimento: string
  nom_medico: string
}


function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function highestOccurrence(arr: string[]){
  return arr.sort((a,b) =>
        arr.filter(v => v===a).length
      - arr.filter(v => v===b).length
  ).pop();
}

export default async function AppointmentLogin(
  cod: string,
  cpf: string, 
  name: string,
) {
  let confirmationObject;
  await AuthService.getUser(cod).then(resp =>{
    let last = 0;
    let doctorName = "";
    let schedule = "";
    const appointments = resp.data.result; //vai olhar as ultimas consultas do paciente
    const patientDoctors: string[] = [];

    appointments.map((appointment: AppointmentProps) =>{
      const aux = new Date(appointment.dat_agenda).getTime();
      patientDoctors.push(appointment.nom_medico); // Adicina todos os medicos que esse paciente ja
                                                  // para poder descobrir o paciente favorito
      if(last < aux){ // olha a ultima consulta do paciente e o ultimo medico dele
        last = aux; 
        schedule = appointment.des_hora;
        doctorName = appointment.nom_medico;
      }
    })
    const today = new Date();
    const lastDate = new Date(last);
    const d1 = today.getTime();
    const d2 = lastDate.getTime();
    const future = d1 < d2;
    if(future){
      const day = lastDate.getDate();
      const month = lastDate.getMonth() + 1;
      let auxDoctor = doctorName.split(" ")[0];
      auxDoctor = auxDoctor.toLowerCase();
      auxDoctor = capitalizeFirstLetter(auxDoctor);
      confirmationObject = {
        day,
        month,
        schedule,
        name,
        auxDoctor,
        future
      }
    }else{
      let patientDoctor = highestOccurrence(patientDoctors);
      let change = false;
      // eh entregue uma nova lista de medicos e tambem conferimos se existe um medico favorito
      const newDoctorOptions = doctorOptions.filter((value: string) =>{
        // Essa parte eh responsavel por pegar o primeiro e ultimo nome do medico para poder comparar com o do konsist
        const Name = value.split(" ");
        Name[1] = Name[1].normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        Name[2] = Name[2].normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        Name[1] = Name[1].toUpperCase();
        Name[2] = Name[2].toUpperCase();
        // Nessa parte ele testa se o medico com maior ocorrencia existe dentro da lista de medicos verdadeiros
        const filterFirst = new RegExp(Name[1]);
        const filterLast = new RegExp(Name[2]);
        if(!(filterFirst.test(patientDoctor ? patientDoctor : "") && filterLast.test(patientDoctor ? patientDoctor : ""))){
            return value;
        }else{
            patientDoctor = value;
            change = true; // se existe de fato
        }
      })
      if(change){
        newDoctorOptions.unshift(patientDoctor ? patientDoctor : "") // adiciona o medico favorito no comeco da lista
      }
      confirmationObject = {
        cpf,
        cod,
        newDoctorOptions,
        future,
      }
    }

    
  })
  return confirmationObject;
}