

interface DateProps{
    date:[
        {
            chave: number;
            dat_agenda: string;
            des_hora: string;
        }
    ]
}

export default function Organize({date}: DateProps){
    date.sort((a, b) => {
        const auxA = new Date(a.dat_agenda).getTime() + parseInt(a.des_hora.slice(0,2) + a.des_hora.slice(3,4));
        const auxB = new Date(b.dat_agenda).getTime() + parseInt(b.des_hora.slice(0,2) + b.des_hora.slice(3,4));
        return auxA - auxB;
    })
    return date;
}