import { Container,  DropdownField, DropdownContent, DropdownItem, StyledDropdow, StyledArrow}  from "./styles";
import { useEffect, useRef, useState } from "react";


type SelectorProps = {
  title: string;
  setSelector: React.Dispatch<React.SetStateAction<any>>;
  selector: string | number;
  options: string[];
  hidden?: number | string;
  background?: number;
  textColor?: number;
  keyRestart?: string;
};


export default function Selector({ title, options, setSelector, hidden, background = 1, textColor = 1, keyRestart }: SelectorProps) {
  
  const wrapperRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [select, setSelect] = useState(title)
  
  useEffect(() =>{
    setSelect(title)
    setSelector("");  
  },[hidden, keyRestart])

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
        function handleClickOutside(ev: any) {
            if (ref.current && !ref.current.contains(ev.target)) {
                setOpen(false);
            }
        }
  
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);



  return (
    <>
    {(hidden !== "" && hidden !== 0) ?  
    <Container>
        <StyledDropdow background={background}>
          <DropdownField
            textColor={textColor} 
            tabIndex={0} 
            type="button" 
            onKeyPress={() => setOpen(!open)}
            onClick={() => setOpen(!open)}
            >
              {select}
          </DropdownField> 
          
          <StyledArrow className="fas fa-caret-down" /> 
        </StyledDropdow>
        {open && 
          <DropdownContent ref={wrapperRef}>
            {options.map(sentence => {
              return (
                  <DropdownItem key={sentence} onClick={() => {
                  setSelect(sentence);
                  setSelector(sentence);
                  setOpen(!open);
                }}>
                    {sentence}
                </DropdownItem>
              )
            })}
          </DropdownContent>
        }
      </Container> : null}
    </>
  );

  
}