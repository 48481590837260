import styled from "styled-components";
import { COLORS } from "../../colors"

export const StyledWindow = styled.div`
  min-height: 90%;
  max-height: 100%;
  min-width: 100%;
  background: ${COLORS.gradient};
  justify-content: center;
  padding-top: 30px;

  @media(max-height: 500px) {
    min-height: 100vh;
    max-height: 140vh;
  }
`;

export const StyledFormComponent = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLORS.primaryColor};
  border-radius: 10px;
  width: 50%;
  max-width: 900px;
  min-width: 300px;
  margin: 0 auto;
  min-height: 85vh;
  -moz-box-shadow: 0px 3px 8px rgb(100, 100, 100);
  -webkit-box-shadow: 0px 3px 8px rgb(100, 100, 100);
  box-shadow: 0px 3px 8px rgb(100, 100, 100);
  @media(max-width: 500px) {
    width: 85%;
  }
  a {
    color: rgba(112,158,180,1);
  }
`;

export const StyledInputsComponent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media(max-width: 500px) {
    width: 85%;
  }
`;

export const Image = styled.img`
  height: 10rem;
  width: 25rem;
  margin-bottom: 5rem;
  @media(max-width: 500px) {
    height: 7rem;
    width: 18rem;
    position: initial;
    left: 0;
  }
`;

export const Header = styled.h1`
  font-size: 20px;
  color: ${COLORS.white};
  font-family: "NimbusSanLBold";
  @media(max-width: 970px) {
    font-size: 25px;
    margin: 0 0 1rem 1rem;
  }
`;

export const Title = styled.p`
  font-size: 20px;
  color: ${COLORS.white};
  font-family: "NimbusSanL";
  @media(max-width: 970px) {
    font-size: 15px;
    margin: 0 0 1rem 1rem;
  }
`;
