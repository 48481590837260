import { InputField, Container, Error, InputFieldMask, StyledInput } from "./styles";


type TextInputProps = {
  text: string;
  type: string;
  name: string;
  value?: string | number | readonly string[] | undefined
  error?: string | undefined;
  mask?: string | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  propsTouched?: boolean;
  propsError?: string;
  err?: string;
  disabled?: boolean;
  id?: string;
};

export default function TextInput({
   text, 
   type, 
   name, 
   error, 
   onChange, 
   propsTouched, 
   propsError, 
   value, 
   mask, 
   err, 
   disabled, 
   id 
  }: TextInputProps) {
  


  return (
    <Container>
      <StyledInput id={id}>
        {mask ? 
          <InputFieldMask mask={mask} maskPlaceholder={null} type={type} name={name} placeholder={text} onChange={onChange} value={value} disabled={disabled} autoComplete={"on"} id={id}></InputFieldMask> : 
          <InputField 
            type={type} 
            name={name}
            placeholder={text} 
            onChange={onChange}
            value={value}
          />
        }
        {error ? <Error id="error" >{error}</Error> : null}
        {(propsTouched === true && propsError !== undefined) || err ? (
        <Error >{propsError || err}</Error>
      ) : null}
      </StyledInput>
    </Container>
  );
}