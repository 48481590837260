import styled from "styled-components";
import { motion } from "framer-motion";
import { COLORS } from "../../colors"

const variants = {
  visible: { opacity: 1, transition: { duration: 1.0 } },
  hidden: { opacity: 0 }
};

export const StyledDiv = styled(motion.div).attrs(() =>( {
  initial: "hidden",
  variants
}))`
  display: flex;
  align-items: center;
  width: 55%;
  height: 10rem;
  margin-bottom: 4rem;
  @media(max-width: 800px) {
    width: 18rem;
    height: 8rem;s
  }
`;

 export const Image = styled.img`
  display: flex;
  justify-content: flex-start;
  width: 10rem;
  height: 10rem;
  border-radius: 5px;
  @media(max-width: 800px) {
    width: 7rem;
    height: 7rem;
  }
 `
export const Text = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: start;
  align-items: center;
  font-family: 'Roboto', sans-serif;
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  font-family: "NimbusSanLBold";
  justify-content: start;
  color: ${COLORS.white};
  padding-left: 3rem;
  font-size: 20px;
  @media(max-width: 800px) {
    font-size: 15px;
  }
`
export const Content = styled.ul`
  width: 100%;
  color: ${COLORS.white};
  font-family: "NimbusSanL";
  display: flex;
  flex-direction: column;
  font-size: 15px;
  padding-left: 5rem;
  justify-content: start;
  @media(max-width: 800px) {
    font-size: 13.5px;
    padding-left: 3.7rem;
  }
`

export const Copy = styled.div`
  width: 100%;
  color: ${COLORS.white};
  display: flex;
  flex-direction: column;
  font-size: 18px;
  padding-left: 5rem;
  justify-content: start;
  @media(max-width: 800px) {
    font-size: 17px;
    width: 90%;
    padding-left: 2.0rem;
  }
`

export const List  = styled.li`
  width: 90%
  @media(max-width: 800px) {
    font-size: 22px;
    
  }
`