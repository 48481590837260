import api from "./api";

interface SignInCredentials {
  cpf: string;
}

interface SignUpCredentials {
  name: string;
  gender: string;
  birthDate: string;
  cpf: string;
  cellPhone: string;
  address: {
    zipCode: string;
    complement: string;
    neighborhood: string;
    street: string; // logradouro
  };
  email: string;
}


function Convert(data: string) {
  const day  = data.split("/")[0];
  const month  = data.split("/")[1];
  const year  = data.split("/")[2];

  return year + "-" + ("0"+month).slice(-2) + "-" + ("0"+day).slice(-2);
}
function CPF(cpf: string){
  cpf = cpf.replace(".", "");
  cpf = cpf.replace(".", "");
  cpf = cpf.replace("-", "");
  return cpf
}

export default {
  
  login({ cpf }: SignInCredentials) {
    const cpfChanged = CPF(cpf);
    return api.get(`/get/user/${cpfChanged}`);
  },
  
  register(values: SignUpCredentials) {
    const cpf = CPF(values.cpf);
    const birthDate = Convert(values.birthDate);
    const firstName = values.name.split(" ")[0];
    const ddd = values.cellPhone.slice(1,3);
    const cellPhone = values.cellPhone.split(" ")[1];
    const gender = values.gender[0];
    const address = values.address.street + "-" + values.address.complement;
    
    return api.post("/create/user", { 
      nom_paciente: firstName,
      nom_paciente_completo: values.name,
      num_cpf: cpf,
      dat_nascimento: birthDate,
      des_sexo: gender,
      des_endereco: address,
      des_bairro: values.address.neighborhood,
      num_ddd1: ddd,
      num_telefone1: cellPhone,
      num_ddd2: ddd,
      num_telefone2: cellPhone,
      num_ddd3: ddd,
      num_telefone3: cellPhone,
      des_email: values.email,
    })
  },
  getUser(cod: string) {
    return api.get(`/get/marking/${cod}`);
  }
};
