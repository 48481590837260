import React, { useEffect, useState } from "react";
import { StyledTimer } from "./styles"

interface TimerProps {
    secondsLimit: number;
    text: string;
}


export default function Timer({secondsLimit, text}: TimerProps){

    const [timerSeconds, setTimerSeconds] = useState(secondsLimit);

    let time: NodeJS.Timeout;

    const startTimer = () =>{
        time = setInterval(() =>{            
            const seconds = timerSeconds - 1;

            if(seconds < 0){
                // parar o timer
                clearInterval(time);
            }else{
                // atualizar o timer
                setTimerSeconds(seconds);
            }


        },1000)
    }

    useEffect(() =>{
        startTimer();
        return () => {
            clearInterval(time)
        }
    })  

    return(
        <StyledTimer>
            {text + "(" + timerSeconds + ")"}
        </StyledTimer>
    )

}