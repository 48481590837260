export default function isCPF (c: string | undefined) {
    
    if(c){
      if((c = c.replace(/[^\d]/g,"")).length != 11)
        return false
        let aux = parseInt(c[0]);
        let same = true;
        for(const prop of c){
          if(aux != parseInt(prop)){
            same = false;
          }
          aux = parseInt(prop);    
        }
        if(same)
          return false;

        if (c === "00000000000")
          return false;
      
        let r;
        let s = 0;
        let i;
        for ( i=1; i<=9; i++)
          s = s + parseInt(c[i-1]) * (11 - i);
      
        r = (s * 10) % 11;
      
        if ((r === 10) || (r === 11))
          r = 0;
      
        if (r !== parseInt(c[9]))
          return false;
      
        s = 0;
      
        for (i = 1; i <= 10; i++)
          s = s + parseInt(c[i-1]) * (12 - i);
      
        r = (s * 10) % 11;
      
        if ((r === 10) || (r === 11))
          r = 0;
      
        if (r !== parseInt(c[10]))
          return false;
      
        return true;
    }else {
      return false
    }
  
  }