import axios from "axios";
// REACT_APP_API_LOCAL_URL
// REACT_APP_API_OCULARE_URL
export const API_URL = process.env.REACT_APP_API_OCULARE_URL;

const api = axios.create({
  baseURL: API_URL,
});
export default api;
// /get/users -> get all users
// /create/users -> create an user
