import React, { useState, useEffect, useCallback } from "react";
import {
    StyledWindow,
    StyledFormComponent,
    StyledButton,
    Title
} from "./styles"
import Button from "../../components/Button";
import OTPInput from "../../components/OTPInput";
import api from "../../services/api"
import AuthService from "../../services/AuthService";
import { useNavigate, useLocation } from "react-router-dom";
import { SignUpProps } from "../SignUp";
import LoadingButton from "../../components/LoadingButtons";
import Timer from "../../components/Timer"
import WppButton from "../../components/WhatsAppButton";
import AppointmentLogin from "../../services/AppointmentLogin";

interface VerifyProps {
    values: SignUpProps;
    signUp: boolean;
    cod: string;
    cellPhone: string;
}

interface AppointmetProps {
    day?: string;
    month?: string;
    schedule?: string;
    name?: string;
    auxDoctor?: string;
    future:boolean;
    cpf?: string;
    cod?: string;
    newDoctorOptions?: string[];
}


export default function Verify(){
    const navigate = useNavigate();
    const [code, setCode] = useState<any[]>();
    const location  = useLocation();
    const state = location.state as VerifyProps;
    const [cellPhone, setCellPhone] = useState(state.cellPhone);
    const signUp = state.signUp;
    const codExam = state.cod;
    const [isLoading, setIsLoading] = useState(false);
    const [isTiming, setIsTiming] = useState(false);

    

    useEffect(() =>{
        let cellPhoneAux = "";
        cellPhoneAux = cellPhone;
        if(cellPhone.length === 15){
            cellPhoneAux = "+55" + cellPhone.slice(1,3) + cellPhone.slice(5,10) + cellPhone.slice(11, 15);
        }else if(cellPhone.length === 14){
            cellPhoneAux = "+55" + cellPhone.slice(1,3) + cellPhone.slice(5,9) + cellPhone.slice(10, 15);
        }
        setCellPhone(cellPhoneAux);
    },[])
    

    const Register = useCallback(
        async (user: SignUpProps) => {
          await AuthService.register(user).then(async (response) => {
            const cod = response.data.resultCPF[0].cod_paciente;
            await api.post(`/post/LGPD/${cod}`).then( async () =>{
                const confirmationObject = await AppointmentLogin(cod, user.cpf, user.name) as unknown as AppointmetProps;
                if(confirmationObject.future){
                    setTimeout(() => {
                        setIsLoading(false);
                        navigate("/confirmation",  {state: {
                            day: confirmationObject.day,
                            month: confirmationObject.month,
                            schedule: confirmationObject.schedule,
                            name: confirmationObject.name,
                            auxDoctor: confirmationObject.auxDoctor
                        }});
                    }, 700);
                }else{
                    setTimeout(() => {
                        setIsLoading(false);
                        navigate("/appointment", {state: {
                            cpf: confirmationObject.cpf,
                            cod: confirmationObject.cod, 
                            newDoctorOptions: confirmationObject.newDoctorOptions, 
                            LGPD: true
                        }})
                    }, 700);
                }
            })

          })
          .catch((error) => {
              console.log(error)
              const number = 32424222;
              window.alert(`Algum erro ocorreu durante o cadastro. Por favor, tente novamente mais tarde ou ligue para ${number}`);
          })
        },
        [AuthService]
    );

    function sendAnotherCode() {
        setIsTiming(true)
        api.post("/post/verify",{
            To: cellPhone,
            Channel:  "sms"
        }).then(() =>{
            setTimeout(()=>{
                setIsTiming(false)
            }, 15000) 
        }).catch(error => {
            console.log(error)
            setIsTiming(false)
        })
    }

    return ( 
        <StyledWindow>
            <StyledFormComponent onSubmit={async (e: React.SyntheticEvent) => {
                e.preventDefault();
                setIsLoading(true);
                let finalCode = "";
                code?.map(number => finalCode = finalCode + number);
                if(finalCode.length === 6){
                    await api.post("/post/confirmation",{
                        To: cellPhone,
                        Code: finalCode
                    }).then(res =>{
                        if(res.data === "approved"){
                            if(signUp){
                            Register(state.values);
                        }else{
                            setTimeout(() => {
                                setIsLoading(false);
                                navigate("/exam", {state: {cod: codExam}} )
                            }, 100);
                        }
                        }else{
                            window.alert("Código de verificação inválido")
                        }
                    }).catch(error =>{
                        console.log(error)
                    })
                }else{
                    window.alert("Código não tem 6 números");
                }
                setIsLoading(false);
            }}>
                <Title>Insira o código de 6 dígitos enviado por SMS para o seu celular</Title>

                <OTPInput setOtpValue={setCode}/>
                {isTiming ?
                    <Timer secondsLimit={15} text="Reenviar o código"/>
                    :
                    <StyledButton onClick={sendAnotherCode} type="button" disabled={isTiming}>Reenviar o código</StyledButton>
                }
                {isLoading ? 
                    <LoadingButton />
                    :
                    <Button text="Conferir" type="submit" />
                }
            </StyledFormComponent>
            <WppButton/>
        </StyledWindow>
    )
}