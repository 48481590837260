import styled from "styled-components";
import { COLORS } from "../../colors"

export const Container = styled.div`
    display: flex;
    width: 101%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
`;

export const StyledDropdow = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-right: 1%;
    align-items: center;
    width: 100%;
    padding-left: 10px;
    padding-right: 5px;
    height: 3.7rem;
    border-radius: 5px;
    background: ${COLORS.secundaryColor};
`

export const DropdownInputField = styled.input`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    font-size: 1.1rem;
    height: 35px;
    color: ${COLORS.white};
    font-family: "NimbusSanL";
    border-radius: 5px;
    background: transparent;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    cursor: pointer;
    ::placeholder {
        color: ${COLORS.lighterWhite};
    }
    &:focus {
    outline: none;
    }
`
export const DropdownContent = styled.div`
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    width: 23%;
    max-height: 145px;
    margin-top: 3.5rem;
    flex-direction: column;
    background-color: ${COLORS.secundaryColor};
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    @media(max-width: 500px) {
        max-height: 220px;
        width: 80%;
    }
`

export const DropdownItem = styled.button`
    color: ${COLORS.white};
    font-family: "NimbusSanL";
    max-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: start;
    cursor: pointer;
    width: 100%;
    border: none;
    background: transparent;
    font-size: 1.1rem;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    // &:hover {
    //     background-color: #f1f1f1
    // }
`
