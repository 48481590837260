// Lista de convenio e medicos que NAO atendem eles
export const filterAgreements = [
  {
    agreement: 1086, // "AMIL",
    doctors: [
      "Dra. Júlia Tibério",
      "Dra. Thais Oliveira",
      "Dr. Talles Petterson",
      "Dr. Yuri Oliveira",
      "Dra. Raianni Pires",
      "Dra. Melina Oliveira",
      "Dra. Raissa Lacerda",
      "Dra. Gisele Morato",
      "Dra. Adriana Costa",
      "Dr. Antônio Márcio Luciano",
      "Dra. Cristiana Bertin",
      "Dr. Jadir Macedo",
      "Dra. Maria Araceli Oliveira",
      "Dr. Tulio Reis",
      "Dra. Letícia Cazar",
      "Dra. Marina Albuquerque",
      "Dra. Junia França",
      "Dra. Juliana Rocha",
      "Dr. Fernando Cresta",
      "Dra. Cristina Souza",
      "Dra. Islara Machado",
    ],
  },
  {
    agreement: 1011, //"BOMBEIROS",
    doctors: ["Dra. Maria Araceli Oliveira"],
  },
  {
    agreement: 11, // "CASSI",
    doctors: ["Dra. Maria Araceli Oliveira"],
  },
  {
    agreement: 175, //"LIFE EMPRESARIAL",
    doctors: ["Dra. Maria Araceli Oliveira"],
  },
  {
    agreement: 1063, // "PREVEN MED",
    doctors: ["Dra. Maria Araceli Oliveira"],
  },
  {
    agreement: 1061, // "SAUDE SIN PG NO ATO",
    doctors: ["Dra. Maria Araceli Oliveira"],
  },
  {
    agreement: 1045, //"SESC PG NO ATO",
    doctors: ["Dra. Maria Araceli Oliveira", "Dr. Tulio Reis"],
  },
  {
    agreement: 1010, // "VIDA CARD/ASSEDF",
    doctors: ["Dra. Maria Araceli Oliveira"],
  },
  {
    agreement: 1062, // "ASFUB",
    doctors: ["Dr. Tulio Reis"],
  },
  {
    agreement: 1067, // "SMILE SAUDE",
    doctors: ["Dr. Tulio Reis"],
  },

  {
    agreement: 5, // "Assefaz",
    doctors: [
      "Dr. Marco Daier",
      "Dr. Samuel de Faria Silva Duarte",
      "Dr. Talles Petterson",
      "Dra. Letícia Cazar",
      "Dr. Yuri Oliveira",
      "Dra. Thais Oliveira",
      "Dra. Cristina Souza",
      "Dra. Júlia Tibério",
      "Dra. Micheline Cresta",
      "Dra. Raianni Pires",
      "Dra. Melina Oliveira",
      "Dra. Gisele Morato",
      "Dra. Adriana Costa",
      "Dr. Antônio Márcio Luciano",
      "Dra. Cristiana Bertin",
      "Dr. Jadir Macedo",
      "Dra. Maria Araceli Oliveira",
      "Dr. Tulio Reis",
      "Dra. Marina Albuquerque",
      "Dra. Junia França",
      "Dra. Juliana Rocha",
      "Dr. Fernando Cresta",
      //"Dr. Leonardo da Cunha Silva Braga",
      //"Dra. Julia Mendonca Ponte Souza",
      "Dra. Isabela Rita de Carvalho Cunha",
      "Opt. Islara Machado",
    ],
  },
  {
    agreement: 1085, // "GDF SAUDE(INAS-DF)",
    doctors: ["Dra. Cristiana Bertin"],
  },
];
