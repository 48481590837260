import React, { useCallback, useEffect, useState } from "react";
import {
    StyledInputsComponent,
    CheckContainer,
} from "./styles"

import Button from "../../components/Button";
import ShowDoctors from "../ShowDoctors";
import Selector from "../../components/Selector";

import AuthService from "../../services/AuthService";
import { useNavigate } from "react-router-dom"
import api from "../../services/api";
import SearchSelectorDoctors from "../SearchSelectorDoctors";
import SearchSelectorDate from "../SearchSelectorDate";
import LoadingButton from "../../components/LoadingButtons";
import Search from "../Search";
import { doctorDescription } from "../../data/doctors";
import CheckBox from "../../components/CheckBox";
import { filterAgreements } from "../../data/agreementsRestriction";

interface AppointmentProps {
    cod_procedimento: string
    dat_agenda: string
    des_hora: string
    des_procedimento: string
    nom_medico: string
}

interface PatientProps{
    cpf: string;
    doctorOptions: string[];
    newPatient: boolean;
    selectedDoctor?: string;
    LGPD: boolean;
}


export default function AppointmentFilter({cpf, doctorOptions, newPatient, selectedDoctor, LGPD}: PatientProps){
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNum, setPhoneNum] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [cod, setCod] = useState("");
    const [payment, setPayment] = useState("");
    const [healthInsurance, setHealthInsurance] = useState(0);
    const [doctor, setDoctor] = useState<string>(selectedDoctor ? selectedDoctor : "");
    const [filteredDoctorOptions, setFilteredDoctorOptions] = useState(doctorOptions)
    const [doctorReponse, setDoctorsResponse] = useState();
    const [date, setDate] = useState(0);
    const paymentOptions = ["Particular", "Convênio"];
    const [healthInsuranceOptions, setHealthInsuranceOptions] = useState([]);
    const [id, setId] = useState<number>();
    const [isChecked, setIsChecked] = useState(false);
    
    
    function capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    
    const requestDoctorOptions = useCallback(async () => {
        await AuthService.login({ cpf }).then((response) => {
            setEmail(response.data.result[0].des_email)
            setName(response.data.result[0].nom_paciente);
            setCod(response.data.result[0].cod_paciente);
            setPhoneNum(response.data.result[0].num_telefone1);
            setBirthDate(response.data.result[0].dat_nascimento);
        }).catch(function (err) {
            console.log(err)
            navigate("/");
        })

        await api.get("/get/doctors").then((response) => {
            // console.log(response.data.result);
            setDoctorsResponse(response.data.result);
        })

    },[])

    const requestHealthInsuranceOptions = useCallback(async () => {
            await api.get("/get/agreements").then((response) => {
                setHealthInsuranceOptions(response.data.result);
            })

    },[])
    

    useEffect(() =>{
        requestHealthInsuranceOptions();
        if(healthInsurance !== 0){
            if(payment === "Particular"){
                setFilteredDoctorOptions(doctorOptions)
            }else{
                let removeDoctors: string[] = [];
                filterAgreements.filter(val =>{
                    if(healthInsurance === val.agreement){
                        removeDoctors = (val.doctors)
                    }
                })
                if(removeDoctors){
                    const removedDoctorsList = doctorOptions.filter(val =>{
                        if(!removeDoctors.includes(val)){
                            return val;
                        }
                    })
                    setFilteredDoctorOptions(removedDoctorsList)
                }
            }
        }
        requestDoctorOptions();
        setDoctor("")
    },[requestHealthInsuranceOptions, healthInsurance, payment])

    async function AppointmentPost(e: React.SyntheticEvent){
        e.preventDefault();
        setIsLoading(true);
        let insurance = healthInsurance;
        if(payment === "Particular"){
            insurance = 1;
        }
        if(!isChecked && !LGPD){
            setIsLoading(false);
        }else{
            if(!LGPD){
                await api.post(`/post/LGPD/${cod}`).catch(error =>{
                    console.log(error)
                })
            }
            await api.put(`put/marking/${date}/${cod}/${insurance}`).then(() => {
                AuthService.getUser(cod).then(response =>{
                    
                    
                    let schedule = "";
                    let last = 0;
                    const appointments = response.data.result;
                    appointments.map((appointment: AppointmentProps) => {
                        const aux = new Date(appointment.dat_agenda).getTime();
                        if(last < aux){
                            last = aux;
                            schedule = appointment.des_hora;
                        }
                    })
                    const lastDate = new Date(last); // Data da ultima consulta marcada
                    const day = lastDate.getDate();
                    const month = lastDate.getMonth() + 1;
                    const year  = lastDate.getFullYear();
                    let auxName = name.split(" ")[0];
                    auxName = auxName.toLowerCase();
                    auxName = capitalizeFirstLetter(auxName);
                    let auxDoctor = doctor.split(" ")[1];
                    auxDoctor = auxDoctor.toLowerCase();
                    auxDoctor = capitalizeFirstLetter(auxDoctor);
                    api.post("post/mail", {
                        name: auxName,
                        email,
                        hora: schedule,
                        data: day + "/" + month + "/" + year,
                        medico: auxDoctor
                    })
                    .catch(err =>{
                        console.log(err);
                    }) 
                    const todayDate = new Date();
                    const loginDate: string = todayDate.getDate() + "/" + (todayDate.getMonth() + 1) + "/" + todayDate.getFullYear() 
                    api.post("/post/sheet", {
                        name,
                        birthDate,
                        doctorName: doctor,
                        date: day + "/" + month + "/" + year,
                        schedule,
                        email,
                        phoneNum,
                        newPatient,
                        loginDate
                    })
                    .catch(err =>{
                        console.log(err);
                    })
                    setTimeout(() => {
                        navigate("/confirmation",  {state: {
                            day,
                            month,
                            schedule,
                            name,
                            auxDoctor
                        }});
                        setIsLoading(false);
                    }, 100);
                })
            }).catch(() => {
                // console.log(error)
            })
        }
    }
    return (
        <>
            <ShowDoctors doctors={doctorDescription} doctor={doctor} />
            <StyledInputsComponent onSubmit={(e: React.SyntheticEvent) => {
              e.preventDefault();
              if(payment === "Particular"){
                setHealthInsurance(1)
                }
              AppointmentPost(e);
            }}>
                <Selector title="Particular ou convênio?" options={paymentOptions} setSelector={setPayment} selector={payment}></Selector>
                {payment === "Convênio" ? 
                    <Search title="Pesquise por um convênio..." objectOptions={healthInsuranceOptions} setSelector={setHealthInsurance} selector={healthInsurance}></Search>
                    :
                    null
                }
                <SearchSelectorDoctors title="Selecione um médico" options={filteredDoctorOptions} setSelector={setDoctor} selector={doctor} response={doctorReponse} setId={setId} hidden={payment === "Convênio" ? healthInsurance : payment}></SearchSelectorDoctors>
                <SearchSelectorDate title="Selecione uma data" setSelector={setDate} selector={date} hidden={doctor} id={id} doctorName={doctor}></SearchSelectorDate> 
                

                {date !== 0 && !LGPD? 
                    <CheckContainer>
                        <CheckBox isChecked={isChecked} setIsChecked={setIsChecked}/>
                    </CheckContainer>
                    :
                    null
                }
                {isLoading ? 
                        <LoadingButton />
                        :
                        <Button text="Marcar consulta" disabled={date === 0 || doctor === "" || (!isChecked && !LGPD)}/> 
                }
            </StyledInputsComponent>
        </>        
    )
}