import React, { useState } from "react";
import {
    StyledWindow,
    Image,
    StyledFormComponent,
    Title,
    StyledInputsComponent,
    Header,
    StyledButton
} from "./styles"


import Logo from "../../assets/logo.png"
import TextInput from "../../components/Input";
import Button from "../../components/Button";
import LoadingButton from "../../components/LoadingButtons";
import isCPF from "../AppointmentCPF/isCPF";
import Modal from "../../components/Modal";


// interface AppointmentProps {
//     cod_procedimento: string
//     dat_agenda: string
//     des_hora: string
//     des_procedimento: string
//     nom_medico: string
// }





export default function LGPD(){
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [modalState, setModalState] = useState(false);


    async function LGPDCheck(
        e: React.SyntheticEvent,
        cpf: string,
      ) {
        e.preventDefault();
        if(isCPF(cpf)){
            console.log(cpf)
        }
      }

      function handleChange(cpf: string) {
        const cpfDocument = document.getElementById("cpf");
        setError("");
        if(cpfDocument){
            cpfDocument.style.borderColor = "rgba(112,158,180,1)";   
        }
        if(cpf?.length === 14){
            if(!isCPF(cpf)){
                if(cpfDocument) cpfDocument.style.borderColor = "tomato";
                setError("CPF inválido");
            }
        }
      }

    return ( 
        <StyledWindow>
            <StyledFormComponent onSubmit={(e: React.SyntheticEvent) => {
              e.preventDefault();
              const target = e.target as typeof e.target & {
                cpf: { value: string };
              };
              const cpf = target.cpf.value;
              setIsLoading(true)
              LGPDCheck(e, cpf);
              setIsLoading(false)
            }}>
                <Image src={Logo} alt="Enterprise photo" /> 
                <Header>
                    Bem-vindo(a)!
                </Header>
                <Title>
                    Digite seu CPF
                </Title>
                <StyledInputsComponent>
                    <TextInput id="cpf" mask="999.999.999-99" text="Digite seu CPF" type="zip" name="cpf" onChange={e => handleChange(e.target?.value)} error={error}/>  
                    {isLoading ? 
                        <LoadingButton />
                        :
                        <Button text="Conferir" disabled={isLoading}/>
                    }
                </StyledInputsComponent>
                <StyledButton onClick={() => setModalState(true)} type="button">Politica</StyledButton>
                <Modal 
                    state={modalState} 
                    setState={setModalState} 
                    title={"Formulário de Pedido de Consentimento"} 
                    text={"Não encontramos seu CPF em nosso banco de dados."}
                    buttonText={"Concordar"}
                />    
            </StyledFormComponent>
        </StyledWindow>
    )
}