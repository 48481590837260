import { Container,  DropdownField, StyledDropdow, Item}  from "./styles";
import { useEffect, useRef, useState, useCallback } from "react";
import Organize from "./organize"
import api from "../../services/api";
import ModalCalendar from "../ModalCalendar"


type SelectorProps = {
  title: string;
  setSelector: React.Dispatch<React.SetStateAction<number>>;
  selector: number | undefined;
  hidden?: string;
  id: number | undefined;
  doctorName: string;
};

interface OptionsProps{
  chave: number;
  dat_agenda: string;
  des_hora: string;
}



export default function SearchSelector({ title, setSelector, hidden, id, doctorName }: SelectorProps) {
  
  const wrapperRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<OptionsProps[]>();
  const [modalState, setModalState] = useState(false);
  const [selectedTime, setSelectedTime] = useState<string>(title)
  const [selectedDay, setSelectedDay] = useState<string>()
  const [filterdeMonthOptions, setFilteredMonthOptions] = useState([""])
  let monthOptions = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro" ];
  
  const pipe = "|"
  

  const search = useCallback(async (doctorId: number ) => {
    await api.get(`/get/schedules/${doctorId}`).then((response) => {
      let result  = response.data.results;
      result = Organize({date: result});
      let finalResult = result.filter((res: OptionsProps) => {
        res.dat_agenda = res.dat_agenda.split("T")[0];
        const final = res.dat_agenda.split("-");
        if(res.des_hora[3] === "0"){
          res.dat_agenda = final[2]+ "/" +final[1]+ "/" +final[0];
          const filtered ={
            chave: res.chave,
            dat_agenda: res.dat_agenda,
            des_hora: res.des_hora
          }
          return filtered;
        }
      })
      finalResult = finalResult.slice(5,128);
      setOptions(finalResult);
      filteredMonth(finalResult);
    })
    
  },[doctorName])
  
  const filteredMonth = useCallback((finalOptions: OptionsProps[]) =>{
    let biggestMonth = "0";
    let biggestYear = "0";
    let smallestMonth = "0"; 
    let smallestYear = "0";
    if(finalOptions){
      biggestMonth = finalOptions[0].dat_agenda.slice(3,5);
      biggestYear = finalOptions[0].dat_agenda.slice(6,10);
      smallestMonth = finalOptions[0].dat_agenda.slice(3,5);
      smallestYear = finalOptions[0].dat_agenda.slice(6,10);
      finalOptions.map((finalOption) =>{
        const auxMonth = finalOption.dat_agenda.slice(3,5);
        const auxYear = finalOption.dat_agenda.slice(6,10);

        // descobrindo se o ano mudou
        if(auxYear >= biggestYear){
          biggestYear = auxYear;
        }else{
          smallestYear = auxYear;
        }

        // se o ano mudou o "maior" mes vai ser sempre do ano seguinte
        if(biggestYear > smallestYear){
          biggestMonth = auxMonth;
        }else{
          // se não mudar o mes vai ter comparacao normal
          if(auxMonth >= biggestMonth){
            biggestMonth = auxMonth;
          }else{
            smallestMonth = auxMonth;
          }
        }
        
      })
    }
    const auxBiggestMonth = parseInt(biggestMonth)
    const auxSmallestMonth = parseInt(smallestMonth)
    if(biggestYear > smallestYear){
      monthOptions = [...monthOptions.slice(auxSmallestMonth-1), ...monthOptions.slice(0,auxBiggestMonth)]
    }else{
      monthOptions = monthOptions.slice(auxSmallestMonth-1, auxBiggestMonth)
    }
    monthOptions = [...monthOptions, "Todos os meses"]
    setFilteredMonthOptions(monthOptions)
  },[doctorName])


  useEffect(() =>{
    if(hidden !== "") {
      if(id) search(id);
      setSelectedTime(title);  
      setSelectedDay("");
      setSelector(0);  
    }
  },[hidden, search])

  function useOutsideAlerter(ref: any, ) {
    useEffect(() => {
        function handleClickOutside(ev: any) {
            if (ref.current && !ref.current.contains(ev.target)) {
                setOpen(false);
            }
        }
  
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef); 
  return (
    <>
    {hidden !== "" ?  
    <Container>
        <StyledDropdow>
          <DropdownField 
            tabIndex={0} 
            type="button" 
            onKeyPress={() => setOpen(!open)}
            onClick={() => setModalState(true)}
            >
              <Item>{selectedTime}</Item>
              {selectedDay ? pipe : null}
              <Item>{selectedDay}</Item>
          </DropdownField> 
        </StyledDropdow>
        <ModalCalendar
          state={modalState}
          setState={setModalState}
          doctorName={doctorName}
          filteredMonth={filterdeMonthOptions}
          options={options}
          setKey={setSelector} 
          setSelectedTime={setSelectedTime} 
          setSelectedDay={setSelectedDay}
        />
      </Container> : null}
    </>
  );

  
}