import { useFormikContext } from "formik";
import { useCallback, useEffect } from "react";
import { Container, Error, InputFieldMask, StyledInput } from "./styles";
import cep from "cep-promise"


type TextInputProps = {
  text: string;
  type: string;
  name: string;
  value?: string | number | readonly string[] | undefined
  error?: string | undefined;
  mask: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  propsTouched?: boolean;
  propsError?: string;
  zip?: boolean;
};

export default function TextInputCEP({ text, type, name, error, onChange, propsTouched, propsError, value, mask, zip}: TextInputProps) {
  const { setFieldValue } = useFormikContext();
  const callCep = useCallback((end: string) => {
    cep(end)
      .then((response: any) => {
        const { neighborhood, street } = response;
        setFieldValue("address.neighborhood", neighborhood);
        setFieldValue("address.street", street);
      })
      .catch(() => {
        // console.log(error);
      });
  }, [setFieldValue]);

  useEffect(() => {
    if(zip){
      const timeOutId = setTimeout(() => {
        if(typeof value === "string"){
          if( value?.length === 9){
            callCep(value!);
          } 
        }
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [callCep, value, zip]);


  return (
    <Container>
      <StyledInput>
        <InputFieldMask 
                mask={mask} 
                maskPlaceholder={null} 
                type={type} name={name} 
                placeholder={text} 
                onChange={onChange}
            />
        {error ? <Error>{error}</Error> : null}
        {(propsTouched === true && propsError !== undefined) ? (
        <Error>{propsError}</Error>
        ) : null}
      </StyledInput>
    </Container>
  );
}